import {ContainerHandler} from "./";

export class ArticleClass extends ContainerHandler {
    constructor(props) {
        super(props);
    }
    /** @override **/
    getTagName() {
        return "article";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }
    /** @override **/
    getName(){
        return "ArticleClass"
    }
}

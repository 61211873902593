import {Button, Header, Input, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {
    _console, Arg,
    ArgList,
    Article,
    DomKeyHandler,
    Form,
    Grid,
    Icon,
    List,
    ListItem,
    Span,
    useArgs
} from "@joloh/jspattern";
import {JsPage} from "./JsPage";
import {ListElementBox} from "../core/mediator/ListElementBox";
import {LessonElementBox} from "../core/mediator/LessonElementBox";
import {actionsTypes} from "../core/redux/redux";
import {Contact} from "./Contact";
import {ClassRoom} from "./ClassRoom";
export const CreateLessonPage = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });
    const [args, setArgs, addElement] = useArgs([]);
    const [store, dispatch] = useReduxProvider();

    Arg("formData", {
        formInput: "",
        items: []
    });


    class Competences {
        constructor(title, level, years_of_practice, experiences, tasks, projects, certifications) {
            this.title = title;
            this.level = level;
            this.years_of_practice = years_of_practice;
            this.experiences = experiences || [];
            this.projects = projects || [];
            this.certifications = certifications || [];
            this.tasks = tasks || [];
        }

        addTask(task) {
            if (!task) return;
            this.tasks.push(task);
        }
        removeTask(task) {
            if (!task) return;
            const index = this.tasks.findIndex(t => t.title === task.title);
            if (index > -1) this.tasks.splice(index, 1);
        }

        addExperience(exp) {
            if (!exp) return;
            this.experiences.push(exp);
        }

        addProject(proj) {
            if (!proj) return;
            this.projects.push(proj);
        }

        addCertificate(certificate) {
            if (!certificate) return;
            this.certifications.push(certificate);
        }

        removeCertificate(certificate) {
            if (!certificate) return;
            const index = this.certifications.findIndex(e => e.title === certificate.title);
            if (index > -1) this.certifications.splice(index, 1);
        }

        removeExperience(exp) {
            if (!exp) return;
            const index = this.experiences.findIndex(e => e.title === exp.title);
            if (index > -1) this.experiences.splice(index, 1);
        }

        removeProject(proj) {
            if (!proj) return;
            const index = this.projects.findIndex(e => e.title === proj.title);
            if (index > -1) this.projects.splice(index, 1);
        }
    }

    class Experiences {
        constructor(title, company, year) {
            this.title = title;
            this.year = year;
            this.company = company;
        }
    }
    const nodeCom = new Competences(
        "Node Js",
        4,
        4
    );

    nodeCom.addExperience(new Experiences());

    const jsCom = new Competences(
        "Javascript",
        5,
        6
    );
    const comps = [];

    jsCom.addExperience(new Experiences());

    const item = {};
    Object.keys(jsCom).map(k => Object.assign(item, {[k]: jsCom[k]}));

    const addCompForm = Form({
        items: [],
    });


    const lessonElementBox = new LessonElementBox({updateDom});

    return  Grid({
        gtr: "1fr 1fr",
        gtc: "2fr 60px 60px",
        //gap: "1rem",
        gta: '"Input NavRight" "Nav Nav" "Items Items"',
        //ai: "top",
        children: [
            lessonElementBox.inputText.form,
            Article({
                id: "add_student",
                style: {gridArea: "NavRight / 2 / NavRight / 2", display: "none", transition: "all 0.3s"},
                children: [
                    Section({
                        className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                        onclick: (e) => {
                            lessonElementBox.simulate("");
                            lessonElementBox.validate(args, setArgs, addElement, dispatch);

                            const form = document.getElementById("formInput");
                            if (form) form.value = "";
                        },
                        children: [
                            Article({
                                className: "icon-container",
                                children: [
                                    Icon({className: ["icon--small"] ,textContent: "person_add"})
                                ]
                            }),
                        ]
                    }),
                ]
            }),
            Article({
                id: "clear_form",
                style: {gridArea: "NavRight / -1 / NavRight / NavRight" , display: "none", transition: "all 0.3s"},
                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                onclick: (e) => {
                    const form = document.getElementById("formInput");
                    // console.log(form);
                    if (form) {
                        form.value = "";
                        lessonElementBox.simulate("");
                    }
                },
                children: [
                    Article({
                        className: "icon-container",
                        children: [
                            Icon({className: ["icon--small"] ,textContent: "clear"})
                        ]
                    }),
                ]
            }),
            Article({
                style: {display: "flex"},
                children: [
                    Section({
                        id: "back_icon",
                        style: {display: "none", gridArea: "Nav / 1 / Nav / 1", transition: "all 0.3s"},
                        children: [
                            Article({
                                style: {margin: "unset", padding: "0.99rem 0 0 0"},
                                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                                onclick: (e) => {
                                    //lessonElementBox.simulate("");
                                    //lessonElementBox.validate(args, setArgs, addElement);
                                    //const form = document.getElementById("formInput");
                                    //if (form) form.value = "";
                                    navigation.goToUrl({url: "/class_room", title: "Class Room", stateObserver, useState, navigation, nextPage: ClassRoom});

                                    //navigation({url: "/class_room", title: "Class Room", nextPage: ClassRoom})
                                },
                                children: [
                                    Article({
                                        className: "icon-container",
                                        children: [
                                            Icon({className: ["icon--small"] ,textContent: "arrow_back_ios"})
                                        ]
                                    }),
                                ]
                            }),
                        ]
                    }),
                    Section({
                        id: "forward_icon",
                        style: {display: "none", gridArea: "Nav / 2 / Nav / 2", transition: "all 0.3s"},
                        children: [
                            Article({
                                style: {margin: "0 0 0 1rem", padding: "0.99rem 0 0 0"},
                                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                                onclick: (e) => {
                                    //lessonElementBox.simulate("");
                                    //lessonElementBox.validate(args, setArgs, addElement);
                                    //const form = document.getElementById("formInput");
                                    //if (form) form.value = "";
                                    navigation({url: "/class_room", title: "Class Room", nextPage: ClassRoom})
                                },
                                children: [
                                    Article({
                                        className: "icon-container",
                                        children: [
                                            Icon({className: ["icon--small"] ,textContent: "arrow_forward_ios"})
                                        ]
                                    }),
                                ]
                            }),
                        ]
                    }),
                ]
            })
        ]
    })
}

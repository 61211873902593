import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
export const Contact = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();


    return Section({
        children: [
            Header({textContent: "Contacts"}),
            Section({
                children: [
                    Paragraph({text : " Lorem ipsum dolor sit amet."}),
                ]
            }),
            Section({
                children: [
                    Button({
                        textContent : " Lorem ipsum dolor sit amet.",
                        onclick: (event) => {
                            navigation.goToUrl({}, "")
                        }
                    }),
                ]
            })
        ]
    })
}

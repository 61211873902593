import {isFunction, isObject} from "../../utils/helpers";

export class PageUpdateSetter {
    constructor(handler) {
        this._handler = handler;
    }

    /**@param data {Object} */
    handle(data) {
        if (!isObject(data)) {
            return new Error(`Parameter should be an object but ${typeof data} given`);
        }
        // if (this._handler) {
        //     console.log("Nice PageUpdateSetter got called  :::: ",  this._handler.getName());
        // }

        this._handler.handle(data);
    }
}

import {ContainerHandler} from "./ContainerHandler";

export class GridClass extends ContainerHandler {
    constructor(props) {
        super(props)
    }

    /** @override **/
    getTagName() {
        return "section";
    }

    /** @override **/
    getClassName() {
        return "";
    }

    /** @override **/
    getName(){
        return "GridClass"
    }

    /** @override **/
    getStyle(props = {}) {
        props = props || {};
        props.style = props.style || {};
        return props.style = {
            ...props.style,
            display: "grid",
            gridTemplate: props.gt || props.style.gridTemplate || null,
            gridTemplateColumns: props.gtc || "repeat(auto-fit, minmax(100px, 1fr)",
            gridTemplateRows: props.gtr || props.style.gridTemplateRows || null,
            rowGap: props.rg || props.style.rowGap || null,
            gap: props.gap || props.style.gap || null,
            columnGap: props.cg || props.style.columnGap || null,
            // Placing items (ex: use -> grid-column:2; on an item to play with the grid items available spaces)
            // by default each item takes 1 cell ex : => grid-column: 1 / 4; 4 represent the end line number on the grid. Use navigator devtools and check the Lines numbers in the layout tab
            // make sure grid overlay is toggled inside the elements tab. If you have three column in the grid then => grid-column: 1 / 3; will make the item take the entire width. -1 is the same for 3 here. But it refers to the last cell
            // the "span" keyword can make the item span to a number of cells like so : grid-column: 1 / span 2;
            gridColumn: props.gc || props.style.gridColumn,
            // grid-row: 2; will place the item on the second row inside the grid. Use Same keywords as rowColumn
            gridRow: props.gr || props.style.gridRow,
            // ex : 4 values (start / end) -> (row / column / row / column) ex : 1 / 1 / 1 / 3 (first two values represent the start and the last two represent the end
            gridArea: props.ga || props.style.gridArea,
            gridAreaName: props.gan || props.style.gridAreaName,
            // Use this approach to place item inside named areas grid-template-areas on the grid and use grid-area on each item of the grid.
            // ex : these double pair of quotes represents the template of the first row.
            // 2 cells in the first row = "header header"
            // 2 cells in the second row = "sidebar main"
            // 2 cells in the last row = "footer footer"
            // so that gives =>
            /*
              grid-template-areas:
               "header  header"
               "sidebar main"
               "footer  footer";

               .box-one {grid-area: header} // no quotes here
               .box-two {grid-area: footer} // no quotes here
            */
            // if you want to remove a cell and have an empty cell use period (.) ex :
            /*
              grid-template-areas:
               ".  footer";
            *
            */
            gridTemplateAreas: props.gta || props.style.gridTemplateAreas,
            // Alignments items of the grid
            // horizontal axis default is stretch (do not add width on items to fill the entire width)
            justifyItems: props.ji || props.style.justifyItems,
            // vertical axis default is stretch (do not add height on items to fill the entire height)
            alignItems: props.ai || props.style.alignItems,
            // Alignments grid inside his grid
            // horizontal axis
            justifyContent: props.jc || props.style.justifyContent,
            // vertical axis
            alignContent: props.ac || props.style.alignContent,
        }
    }
}

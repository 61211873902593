
import {Handler, HTMLHandler} from "../Handler";
import {DomParamsHandler} from "../DomParamsHandler";
import {_console, setElementAttributes} from "../../../../utils/helpers";
import {DomKeyHandler} from "../DomKeyHandler";

export class AttributesContentHandler extends HTMLHandler {
    static result = [];
    /**@param next {HTMLHandler}*/
    constructor(next) {
        super(next);
    }
    static #_checkAttributesContent(children, savedValues) {
        children.forEach((child) => {
            let existingData = savedValues.find((item) => {
                return Object.keys(item)[0] === child.dataset.domKey
            });

            const element = existingData && existingData[Object.keys(existingData)[0]];

            if (element && element.dataset && element.dataset.domKey === child.dataset.domKey) {
                const childAttributes = child.attributes;
                if (childAttributes) {
                    Array.from(childAttributes).forEach(cA => {
                        setElementAttributes({value: cA, element: child});
                    });
                }
                child.innerHTML = element.innerHTML
            }
            if (child.children) {
                AttributesContentHandler.#_checkAttributesContent(Array.from(child.children), savedValues);
            }
        });
    }



    /**@override
     * @param children
     * @param savedValues
     * */
    doHandle(children, savedValues) {
        if (!children || !savedValues) return;
        AttributesContentHandler.#_checkAttributesContent(children, savedValues);
        const currentEditedTextContent = AttributesContentHandler.result && AttributesContentHandler.result.pop();
        if (currentEditedTextContent) {
            // _console("currentEditedValue Attributes popped", currentEditedTextContent);
            AttributesContentHandler.result.splice(0);
        }
        return false;
    }

    getName() {
        return "AttributesContentHandler";
    }
}

import {setElementAttributes} from "../../utils/helpers";

export class DomManager {
  element = null;
  constructor(option) {
    if (option) {
      this.createElement(option.tagName);
      if (option.className) this.addClass(option.className);
      if (option.textContent) this.element.textContent = option.textContent;
      if (option.innerHTML) this.element.innerHTML = option.innerHTML;
      if (option.style) setElementAttributes({value: option.style, key: "style", element: this.element});
    }
  }

  createElement(tagName) {
    this.element = tagName ? document.createElement(tagName) : null;
    return this;
  }

  appendChild(element, child) {
    element.appendChild(child);
    return this;
  }


  getElement(){
    return this.element;
  }

  addClass(className) {
    const rule = this.element && className;
    if (rule) this.element.classList.add(className);
    return this;
  }
}

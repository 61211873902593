
import {HTMLHandler} from "../Handler";
import {_console, setElementAttributes} from "../../../../utils/helpers";

export class TextContentHandler extends HTMLHandler {
    static result = [];
    /**@param next {HTMLHandler}*/
    constructor(next) {
        super(next);
    }
    static #_checkTextContent(children, savedValues) {
        children.forEach((child) => {
            let existingData = savedValues.find(item => {
                return Object.keys(item)[0] === child.dataset.domKey
            });
            const element = existingData && existingData[Object.keys(existingData)[0]];

            if (element && element.dataset && element.dataset.domKey === child.dataset.domKey && element.textContent && element.textContent !== child.textContent) {
                TextContentHandler.result.push({oldChild: child, newChild: element});
                child.innerHTML = element.innerHTML;
            }
            if (child.children) {
                TextContentHandler.#_checkTextContent(Array.from(child.children), savedValues);
            }
        });
    }

    /**@override
     * @param children
     * @param savedValues
     * */
    doHandle(children, savedValues) {
        if (!children || !savedValues) return;
        TextContentHandler.#_checkTextContent(children, savedValues);
        const currentEditedTextContent = TextContentHandler.result && TextContentHandler.result.pop();
        if (currentEditedTextContent) {
            TextContentHandler.result.splice(0);
        }
        return false;
    }

    getName() {
        return "TextContentHandler";
    }
}

import {isHome, pageName, pageTitle} from "../../utils/helpers";
import {Main} from "../../components";
import {AddCvPage} from "../../../../../../src/views/AddCvPage";
import {Home} from "../../../../../../src/views";

export class Navigator {
    constructor(option) {
        this.navigate(option);
    }

    // USE MEMENTO PATTERN FOR NAV BACK AND NAV FORWARD

    navigate(option){
        let self = this;
        history.pushState(null, option.title, isHome ? "/" : option.url);
        if (option.dataSource && isHome) option.dataSource.setValue({nextPage: Home, url: "/"});
    }

    goToUrl(option, domElementId = null){
        // fix this to reduce number of params to 3
        const {url, title, nextPage, stateObserver, dataSource, isLast} = option || {};
        // console.log("dataSource ::: ", dataSource);
        if (!url || !nextPage) return;
        document.title = title;
        const doc = document.getElementById(domElementId || "root");
        if (dataSource && !isLast) dataSource.setValue({nextPage, url});
        const dataSourceValue = dataSource.getValue();
        // console.log("dataSourceValue :::  ", dataSourceValue);
        const page = dataSourceValue && dataSourceValue.nextPage(stateObserver) || nextPage(stateObserver);
        const _url = dataSourceValue && dataSourceValue.url || url;

        if (page && doc) {
            page.id = doc.id;
            const parent = Main({attributes: {id: doc.id}});
            parent.append(page);
            doc.replaceWith(parent);
            // console.error("url:::  ", _url);
            history.pushState({}, title, _url);
        }
    }

    start(option, pageObjectKey = null) {
        option = option || {};
        const title = option.title || pageTitle || "404";
        return {value: option[pageObjectKey] || option[pageName] , title: title };
    }
}

import {Handler} from "./Handler";
import {isObject, setElementAttributes} from "../../../utils/helpers";

export class DomUpdateHandler extends Handler {
    /**@param next {Handler}*/
    constructor(next) {
        super(next);
    }

    /**
     * @param option {Object}
     * @description option must have two keys element & data
     * element is NodeElement (HtmlElement) and data is an object of attributes ex {style: {color: "white" }}
     * @return {void}
     *
     */
    static #_updateContent(option) {
        option = option || {};
        const {data, show, hide} = option || {};
        let element = option.element;
        if (typeof element === "string") element = document.getElementById(element) || document.querySelector(element);
        if (show !== undefined) element.style.display = show ? "block": "none";
        if (hide !== undefined) element.style.visibility = hide ? "hidden": "visible";
        setElementAttributes({value: data, element: element});
    }

    /**@override
     * @param option {Object}
     * */
    doHandle(option) {
        if (!isObject(option)) {
            return new Error(`Parameter should be an object but ${typeof option} given`);
        }
        DomUpdateHandler.#_updateContent(option);
        return false;
    }

    getName() {
        return "DomUpdateHandler";
    }
}

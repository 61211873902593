import {
    _console,
    Article,
    Form,
    Grid,
    Header, Icon,
    Image,
    List,
    ListItem,
    Section,
    Span,
    StateManager, useArgs, useReduxProvider
} from "@joloh/jspattern";
import * as SearchIcon from "../../assets/icons/search.svg";
import {ClassRoom} from "./ClassRoom";
import {CreateLessonPage} from "./CreateLessonPage";
import {Home} from "./Home";
import {CVZone} from "./CVZone";
import {Formations} from "./Formations";
import {AddCvPage} from "./AddCvPage";
import {Contact} from "./Contact";

export const NavBar = (params) => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom, dataSource} = params({
        text: "text",
        image: null
    });
    const [store, dispatch] = useReduxProvider();
    const [args, setArgs, addElement] = useArgs();
    const setText = useState("text");
    const setIsOn = useState("isOn");

    function goToUrl({url, title, nextPage}) {
        navigation.goToUrl({url: url, title: title, stateObserver, useState, navigation, dataSource, nextPage: nextPage});
    }
    return Section({
        className: ["nav-container"],
        children: [
            Section({
                className: "navbar",
                style: {
                    display: "grid",
                    //width: "400px !important",
                    //height: "200px !important",
                    //gridTemplate: `"Logo" "Search" "Nav" 1fr / 1fr 1fr 1fr`,
                    gridTemplateColumns: "1fr 1fr",
                    //gridArea: "Logo / Search / Nav",
                    gridTemplateAreas: `
                        "Logo Logo" 
                        "Navigation Navigation"
                        `,
                },
                children: [
                    // LOGO
                    Header({
                        className: "nav-logo",
                        textContent: "JSPATTERN",
                        style: {gridAreaName: "Logo", alignSelf: "center"},
                        onclick: function (e) {
                            // goToUrl({url: "/", title: "Accueil - JSPattern", nextPage: Home})
                        },
                    }),
                    // Search
                    Section({
                        className: ["input-group"],
                        style: {display: "none", gridAreaName: "Search"},
                        children : [
                            Image({
                                className:["icon", "icon--small", "icon-small"],
                                src: SearchIcon
                            }),
                            Form({
                                className: 'nav-search',
                                items: [
                                    {name: "search", attributes: {placeHolder: "Recherche..."}},
                                ]
                            })
                        ]
                    }),
                    Section({
                        style: {width: "max-content", gridAreaName: "Navigation"},
                        tagName: "nav",
                        className: "nav",
                        children: [
                            Grid({
                                gtc: "1fr auto",
                                ac: "end",
                                children: [
                                    List({
                                        className: 'nav-menu',
                                        children: [
                                            ListItem({
                                                //onclick: (e) => {
                                                //    navigation.goToUrl({url: "/create_lesson", title: "Create Lesson", stateObserver, useState, navigation, nextPage: CreateLessonPage});
                                                //},
                                                className:["nav-menu__item"],
                                                children: [
                                                    Article({
                                                        className: ["icon-container"], children: [
                                                            Icon({textContent: "search",}),
                                                    ]})
                                                ]
                                            }),
                                            ListItem({
                                                className:["nav-menu__item"],
                                                onclick: (e) => {
                                                    goToUrl({url: "/cv_zone", title: "Zone CV", stateObserver, useState, navigation, nextPage: CVZone});
                                                },
                                                children: [
                                                    Article(
                                                        { className: ["icon-container"],
                                                            style: {borderRadius: "0.7rem"},
                                                            children: [
                                                                Span({style: {fontSize: "1.6rem"},className: ["nav-menu__item__text"] ,textContent: "Zone CV"})
                                                            ],
                                                        })
                                                ]
                                            }),
                                            ListItem({
                                                className:["nav-menu__item"],
                                                onclick: (e) => {
                                                    goToUrl({url: "/formations", title: "Formations", stateObserver, useState, navigation, nextPage: Formations});
                                                },
                                                children: [
                                                    Article(
                                                        { className: ["icon-container"],
                                                            style: {borderRadius: "0.7rem"},
                                                            children: [
                                                                Span({style: {fontSize: "1.6rem"},className: ["nav-menu__item__text"] ,textContent: "Formations"})
                                                            ]
                                                        })
                                                ]
                                            }),
                                            ListItem({
                                                onclick: (e) => {
                                                    goToUrl({url: "/class_room", title: "Class Room", stateObserver, useState, navigation, nextPage: ClassRoom});
                                                },
                                                className:["nav-menu__item"],
                                                children: [
                                                    Article({ className: ["icon-container"], children: [
                                                            Icon({className: ["icon--small"], textContent: "groups",}),
                                                        ]
                                                    })
                                                ]
                                            }),
                                            ListItem({
                                                onclick: (e) => {
                                                    goToUrl({url: "/create_lesson", title: "Create Lesson", stateObserver, useState, navigation, nextPage: CreateLessonPage});
                                                },
                                                className:["nav-menu__item"],
                                                children: [
                                                    Article({ className: ["icon-container"], children: [
                                                            Icon({className: ["icon--small"], textContent: "history_edu",}),
                                                        ]
                                                    })
                                                ]
                                            }),
                                            ListItem({
                                                className:["nav-menu__item"],
                                                onclick: (e) => {
                                                    goToUrl({url: "/add_cv", title: "Ajouter votre CV de dev", stateObserver, useState, navigation, nextPage: AddCvPage});
                                                },
                                                children: [
                                                    Article({ className: ["icon-container"], children: [
                                                            Icon({className: ["icon--small"], textContent: "post_add",}),
                                                        ]
                                                    })
                                                ]
                                            }),
                                            ListItem({
                                                id: "nav_menu_icon",
                                                className:["nav-menu__item", "nav-menu__icon"],
                                                onclick: (e) => {
                                                    goToUrl({url: "/", title: "Accueil - JSPattern", stateObserver, useState, navigation, nextPage: Home});
                                                },
                                                children: [
                                                    Article({ className: ["icon-container"], children: [
                                                            Icon({className: ["icon--small"], textContent: "menu",}),
                                                        ]
                                                    })
                                                ]
                                            })
                                        ]
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
        ]
    })
}

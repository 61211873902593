import {Handler} from "./Handler";
import {isObject} from "../../../utils/helpers";

export class ObserverFieldHandler extends Handler {
    /**@param next {Handler}*/
    constructor(next) {
        super(next);
    }
    /**@override
     * @param option {Object}
     * */
    doHandle(option) {
        if (!isObject(option)) {
            return new Error(`Parameter should be an object but ${typeof option} given`);
        }
        // console.log(`${this.getName()} is set  :::: `,  option.stateObserver !== null && option.stateObserver !== undefined);

        return !option.stateObserver;
    }

    getName() {
        return "ObserverFieldHandler";
    }
}

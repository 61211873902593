import {DataSource} from "../patterns/observer";

export class StateManager {
    static _dataSource = new DataSource();

    static useState(key) {
        return (value) => StateManager.setValue(StateManager._observer, value, key);
    }

    // SEPARATE CONCERNS
    static addObserver(observer) {
        StateManager._dataSource.addObserver(observer);
    }

    static setObserver(observer, initialData) {
        StateManager._observer = observer;
        observer.setInitialData(initialData);
        observer.setDataSource(StateManager._dataSource);
    }

    static setValue(observer, value, key) {
        const oldValue = observer.getValue();
        observer._dataSource.setValue({
            ...oldValue,
            [key]: value
        });
    }
}

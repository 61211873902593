import {UIControl} from "./UiControl";
import {DomKeyHandler} from "@joloh/jspattern";

export class ButtonBox extends UIControl {
    constructor(props) {
        super();
        this.setEnabled(props);
    }

    createItem(props) {
       // const element = this.createElement(props);
    }

    isEnabled() {
        return this._enabled;
    }

    setEnabled(enabled = false){
        this._enabled = enabled;
        this._notifyEventHandlers();
    }

    editButton(edited){
        this._isEdited = edited;
        this._notifyEventHandlers();
    }

    /**@override*/
    createElement(props) {
        // const props = this.this.props;
        // const element = document.createElement(props.tagName || "div");
        // element.textContent = props.text || props.textContent || props.innerText || props.innerHTML;
        // PIPELINE TO SET EACH ATTRIBUTTES

        return document.createElement(props.tagName || "div");
    }

}

// item must pass through a pipeline on start. Each time the item is edited. It must notify the other items so they can update themeselve

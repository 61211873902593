import {Handler} from "./Handler";
import getId from "./../../../utils/IdGenerator";
import {AttributesIterator, useIterator} from "../iterator";

export class DomKeyHandler extends Handler {
    static count = 0;
    static _currentValue = {};
    /**@param next {Handler}*/
    constructor(next) {
        super(next);
    }

    /**
     * @param current
     * using
     *
     * */
    static #iterationCallback(current) {
        DomKeyHandler.count++;
        let id = getId([DomKeyHandler.count]);
        DomKeyHandler.#_saveCurrent(current, id);
    }

    static #_checkChildren(currentList) {
        if (currentList) {
            currentList = Array.from(currentList);
            const iterator = new AttributesIterator();
            iterator.browser.set(currentList);
            useIterator({iterator, callback: DomKeyHandler.#iterationCallback});
        }
    }


    static #_saveCurrent(child, id) {
        if (!child.dataset.domKey) {
            child.dataset.domKey = id;
            Object.assign(DomKeyHandler._currentValue, {[child.dataset.domKey]: child});
            if (child.children && Array.from(child.children).length > 0) {
                DomKeyHandler.#_checkChildren(Array.from(child.children));
            }
        }
    }

    /**@override
     * @param element {Object}
     * */
    doHandle(element) {
        element = element || {};
        DomKeyHandler.#_checkChildren([element.page]);
        // needed to delete count in order to always get the same id
        DomKeyHandler.count = 0;
        return false;
    }

    getName() {
        return "DomParamsHandler";
    }
}

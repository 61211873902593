import {isFunction, isObject} from "../../utils/helpers";
import {StateManager} from "./StateManager";
import {useReduxProvider} from "../redux/ReduxProvider";
import {
    DomFieldHandler,
    ObserverFieldHandler,
    ProviderFieldHandler,
    StateFieldHandler,
    NavigationFieldHandler,
} from "../patterns/chain";
import {ObjectFieldSetter} from "./ObjectFieldSetter";
import {AddCvPage} from "../../../../../../src/views/AddCvPage";


export class PageSetter {
    constructor(handler, options) {
        options = options || {};
        if (options.reducer && options.initialState && isFunction(options.reducer)) {
            PageSetter._hasRedux = true;
            PageSetter._useReduxProvider = () => useReduxProvider({reducer: options.reducer, initialState: options.initialState});
        }
        PageSetter._stateObserver = options.stateObserver;
        PageSetter._navigation = options.navigation;
        PageSetter._dataSource = options.dataSource;

        this._handler = handler;
    }



    static #_useObserver(initialData = {}) {
        StateManager.setObserver(PageSetter._stateObserver, initialData);
    }

    static #_setPageParams(initialData, updateDom) {
        StateManager.addObserver(PageSetter._stateObserver);
        PageSetter.#_useObserver(initialData);
        return PageSetter.#_setObserver({initialValue: initialData, useObserver: PageSetter.#_useObserver, stateObserver: PageSetter.#_useObserver, navigation: PageSetter._navigation, updateDom, dataSource: PageSetter._dataSource});
    }

    static #_setObserver ({initialValue, useObserver, stateObserver, navigation, updateDom, dataSource}) {
        // FIX THIS TO AVOID SENDING stateObserver TWICE chain of responsibility pattern
        useObserver(initialValue);

        const domField = new DomFieldHandler(null);
        const observerField = new ObserverFieldHandler(domField);
        const reduxProviderField = new ProviderFieldHandler(observerField);
        const stateField = new StateFieldHandler(reduxProviderField);
        const navigationField = new NavigationFieldHandler(stateField);
        const objectFieldSetter = new ObjectFieldSetter(navigationField);

        const _setObserverParams = (initialData) => ({
            initialValue: {...initialValue, ...initialData},
            useObserver,
            stateObserver,
            navigation,
            updateDom,
            dataSource
        })

        const finalData = {
            updateDom: updateDom,
            dataSource,
            stateObserver,
            useReduxProvider: PageSetter._hasRedux ? PageSetter._useReduxProvider : function () {
                return [new Error("Please provide a reducer function and an object as initial data from App")];
            },
            useState: StateManager.useState,
            navigation: {
                ...navigation,
                goToUrl: (option) => navigation.goToUrl({
                    ...option,
                    stateObserver: (initialData) => PageSetter.#_setObserver(_setObserverParams(initialData))
                })
            }
        };

        objectFieldSetter.handle(finalData);
        return finalData;
    }

    // chain of responsibility pattern
    /**@param data {Object} */ // Todo Might Need to use functions Hope Not
    handle(data) {
        if (!isObject(data)) {
            return new Error(`Parameter data should be an object but ${typeof data} given`);
        }
        const {value, title, updateDom, initialState, reducer} = data || {};
        if (!isFunction(value)) {
            return new Error(`Parameter should be a function but ${typeof value} given. You probably forgot to add a route`);
        }
        this.page = value((initialState) => PageSetter.#_setPageParams(initialState, updateDom));
        this._handler.handle({page: this.page, title: title, setPageParams: PageSetter.#_setPageParams, initialState, reducer});
    }
}

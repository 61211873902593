import {ContainerHandler} from "./ContainerHandler";

/**@abstract*/
export class ParamsHandler extends ContainerHandler {
    constructor(props) {
        super(props);
    }
    /**@abstract*/
    setObjectList() {}
}

import {Handler} from "./Handler";

export class PageParamsHandler extends Handler {
    /**@param next {Handler}
     *
     * */
    constructor(next) {
        super(next);
    }

    // TODO
    setParams(options) {
        options = options || {};
    }

    /**@override
     * @param data {Object}
     *
     * */
    doHandle(data) {
        // if (this._next) {
        //     console.log("PageParamsHandler Next Next Next :::: ",  this._next.getName());
        // }
        // _console("PageParamsHandler data", data);
        return false;
    }

    getName() {
        return "PageParamsHandler";
    }
}

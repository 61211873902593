import {ListBox} from "./ListBox";
import {TextBox} from "./TextBox";
import {ButtonBox} from "./ButtonBox";

export class ListElementBox  {
    constructor(option) {
        this.listBox = new ListBox();
        this.textBox = new TextBox();
        this.buttonBox = new ButtonBox();
        const self = this;
        this.listBox.addEventHandler(new class MediatorHandlerTest {
            handle() {
                self.itemsSelected();
            }
        });
        this.textBox.addEventHandler(new class MediatorHandlerTest {
            handle() {
                self.textChanged();
            }
        })
    }

    simulate(text) {
        this.listBox.setSelection(text);
        this.textBox.setContent(text);
        // console.log("TextBox::: " + this.textBox.getContent());
        // console.log("Button::: " + this.buttonBox.isEnabled());

        return this.buttonBox.isEnabled();
    }

    itemsSelected() {
        // console.log("iiiiiiii");
        this.textBox.setContent(this.listBox.getSelection());
        this.buttonBox.setEnabled(!this.isEmpty(this.listBox.getSelection()));
    }


    isEmpty(content) {
        return typeof content === "string" && content.length <= 0
    }

    textChanged() {
        const content = this.textBox.getContent();
        let isEmpty = this.isEmpty(content);
        this.buttonBox.setEnabled(!isEmpty);
    }
}

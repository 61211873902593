import {UIControl} from "./UiControl";
import {DomKeyHandler} from "@joloh/jspattern";

export class ListBox extends UIControl {
    constructor(selection) {
        super();
        this.setSelection(selection);
    }

    getSelection() {
        return this.selection;
    }

    setSelection(selection){
        this.selection = selection;
        this._notifyEventHandlers();
    }

    /**@override*/
    createElement(props) {
        console.log(props);
        const element = document.createElement(props.tagName || "div");
        // element.textContent = props.text || props.textContent || props.innerText || props.innerHTML;
        // PIPELINE TO SET EACH ATTRIBUTTES

        return document.createElement(props.tagName || "div");
    }
}

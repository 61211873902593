import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {JsPage} from "./JsPage";
import {NavigationHandler} from "../core/classes/NavigationHandler";
export const NodePage = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();

    const nodeList = [
        {
            header: "Système de fichiers (FS)",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Gestion de Flux (Stream) & Buffer",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 3,
        },
        {
            header: "Modules",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Gestion d' événements",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Serveur / Api / Express",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Node et le Front-End",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
    ];

    return Section({
        children: [
            CardList({header:  "Node JS", items: nodeList, navigation: navigation.goToUrl}),
        ]
    })
}

import {App, _console} from "@joloh/jspattern";
import {Contact, Docs, Home} from "./views";
import reducer, {initialState} from "./core/redux/redux";
import {UmlPage} from "./views/UmlPage";
import {HtmlPage} from "./views/HtmlPage";
import {GraphQlPage} from "./views/GraphQlPage";
import {JsPage} from "./views/JsPage";
import {NodePage} from "./views/NodePage";
import {ReactJsPage} from "./views/ReactJsPage";
import {ReactNativePage} from "./views/ReactNativePage";
import {SqlPage} from "./views/SqlPage";
import {FlutterPage} from "./views/FlutterPage";
import {CssLibsPage} from "./views/CssLibsPage";
import {PhpPage} from "./views/PhpPage";
import {JavaPage} from "./views/JavaPage";
import {CssPage} from "./views/CssPage";
import {AddCvPage} from "./views/AddCvPage";
import {CreateLessonPage} from "./views/CreateLessonPage";
import {ClassRoom} from "./views";
import {Formations} from "./views/Formations";
import {CVZone} from "./views/CVZone";

export let visitedPages = [];

_console("visitedPages", visitedPages);

new App({
    home: Home,
    // profile: View({
    //     title: "Profile",
    //     // body: Text("Carl"),
    //     children: [
    //         "Hello nono",
    //         Text("Momo"),
    //         Text({text: "Momo", onkeydown: (e) => {console.log(e)}, style: {color: "purple"}, onclick: (e) => console.log(e)}),
    //         // [
    //         //     "Hello nono",
    //         //     Text("Momo"),
    //         //     Text({text: "Momo", style: {color: "red"}, onclick: (e) => console.log(e)}),
    //         //
    //         //     // List("Article"),
    //         //     // Button("Roro")
    //         // ]
    //         // Text({children: "Momo", style: {color: "red"}, onclick: (e) => console.log(e)}),
    //         // Text({children: ["Momo"], style: {color: "red"}, onclick: (e) => console.log(e)}),
    //         // Text({children: [Text("abc")], style: {color: "red"}, onclick: (e) => console.log(e)}),
    //         // [
    //         //     "Hello nono",
    //         //     Text("Momo"),
    //         //     Text({text: "Momo", style: {color: "red"}, onclick: (e) => console.log(e)}),
    //         //
    //         //     // List("Article"),
    //         //     // Button("Roro")
    //         // ]
    //         // List("Article"),
    //         // Button("Roro")
    //     ]
    // }),
    contact: Contact,
    uml: UmlPage,
    java: JavaPage,
    html: HtmlPage,
    graphql: GraphQlPage,
    flutter: FlutterPage,
    css_libs: CssLibsPage,
    css: CssPage,
    formations: Formations,
    cv_zone: CVZone,
    php: PhpPage,
    javascript: JsPage,
    class_room: ClassRoom,
    add_cv: AddCvPage,
    create_lesson: CreateLessonPage,
    node_js: NodePage,
    react_js: ReactJsPage,
    react_native: ReactNativePage,
    sql: SqlPage,
    docs: Docs,
}, initialState, reducer);

if (location.pathname.includes("create_lesson") || location.pathname.includes("add_cv")) document.getElementById('root').children[0].style.display = "block";

import {UIControl} from "./UiControl";
import {Form, setElementAttributes, useArgs} from "@joloh/jspattern";
import {Input} from "@joloh/jspattern/src/components";
import {isObject} from "@joloh/jspattern/src/utils/helpers";

export class InputBox extends UIControl {
    constructor(content, options) {
        super();

        this.setContent(content);
        this.input = Input({
            ...options,
            attributes: {
                ...options.attributes,
            }
        });
        this.form = Form({
            id: "form",
            //style: {gridArea: "Input / 1", gridColumn: "1 / 2"},
            children: [
                this.input
            ]
        })
    }


    setContent(content) {
        this.content = content;
        // console.warn(this.content);
        this._notifyEventHandlers();
    }

    getContent(){
        return this.content;
    }

    /**@override*/
    createElement() {
        const props = this.getContent();
        // console.error(isObject(props));
        const element = document.createElement(isObject(props) ? props.tagName : "p");
        element.textContent = isObject(props) ? props.text || props.textContent || props.innerText || props.innerHTML : props;
        if (isObject(props)) setElementAttributes({value: props, element: element});
        return element;
    }
}

/**@interface*/
export class IteratorInterface {
    /**@abstract*/
    next() {}
    /**@abstract*/
    current() {}
    /**@abstract*/
    hasNext() {}
    // Personal out of normal Iterator Pattern
    /**@abstract*/
    getName() {}
}

export class AttributesIterator extends IteratorInterface {
    _index = 0;
    constructor() {
        super();
        this.browser = new Browser();
    }
    /**@override*/
    current() {
        return this.browser._items[this._index];
    }
    /**@override*/
    hasNext() {
        return this._index < this.browser._count;
    }
    /**@override*/
    next() {
        this._index++;
    }
    /**@override*/
    getName() {
       return "AttributesIterator";
    }
}

class Browser {
    _count = 0;
    constructor() {
        this._items = [];
    }

    pop() {
      return this._items[--this._count];
    }

    set(items = []) {
       this._items = items;
       this._count = items.length;
    }

    push(item) {
        this._items[this._count++] = item;
    }
}




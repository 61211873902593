export class ArgumentsProvider {
    static args = {}
    static _instance = new ArgumentsProvider();
    /** @type ArgumentsHandlerInterface */
    handler;
    static getInstance() {
        return ArgumentsProvider._instance;
    }

    setHandler(handler) {
        this.handler = handler;
    }

    handle({key, value, isList = false}) {
        if (this.handler) this.handler.handle({key: key, value: value, setArgs: isList ? ArgumentsProvider.addElement : ArgumentsProvider.setArgs});
    }

    static setArgs(key, value) {
        if (!key || typeof key !== "string") return;
        ArgumentsProvider.args[key] = value;
    }

    static addElement(key, value) {
        if (!key || typeof key !== "string") return;
        if (!ArgumentsProvider.args[key]) {
            ArgumentsProvider.args[key] = [];
        }
        ArgumentsProvider.args[key].push(value);
    }
}

import {_console} from "../../utils/helpers";
import {ParamsHandler} from "./ParamsHandler";

/**@abstract*/
export class Parameters extends ParamsHandler {
    constructor(props) {
        super(props);

    }
    _inputKeyDown(key, event) {
        if (event && event.target.value) {
            _console("missing implementation","please add function keydown on this element" + event.target);
        }
    }
}

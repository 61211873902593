export class Context {
    /**@param provider {Provider}*/
    constructor(provider) {
        this._provider = provider;
    }

    setStore(value) {
        this._provider.setStore(value);
    }

    getStore() {
        return this._provider.getStore();
    }
}

/**
 * @param key {string}
 * @param value
 * */


import {ArgumentsProvider} from "./ArgumentsProvider";
import {ArgumentsHandler} from "./ArgumentsHandler";

export const Arg = function (key, value) {
    if (typeof key !== "string" || !key) return;
    const argProvider = ArgumentsProvider.getInstance();
    argProvider.setHandler(new ArgumentsHandler(null));
    argProvider.handle({key, value});
}


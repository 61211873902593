import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {Home} from "./Home";
import {Contact} from "./Contact";
export const Docs = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();

    function goToUrl({url, title, page, event}) {
        if (!url || !page) return;
        navigation.goToUrl({nextPage: page, url: url, title: title || "Home", stateObserver, useState, navigation});
    }



    return Section({
        className: "docs",
        children: [
            Header({textContent: "Documentation"}),
            Section({
                children: [
                    Button({
                        textContent : " Lorem ipsum dolor sit amet.",
                        onclick: (event) => {
                            goToUrl({url: "/", page: Home});
                        }
                    }),
                ]
            })
        ]
    })
}

import {Observer} from "../patterns/observer";

export class StateObserver extends Observer {
    constructor() {
        super();
    }

    update() {
        // _console("State got notified ", this._dataSource && this._dataSource.getValue());
    }

}

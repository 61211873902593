import {Handler} from "./Handler";
import {isHome} from "../../../utils/helpers";
import {DomKeyHandler} from "./DomKeyHandler";
import {HtmlParamsSetter} from "./htmlElementsChain/HtmlParamsSetter";
import {TextContentHandler} from "./htmlElementsChain/TextContentHandler";
import {AttributesContentHandler} from "./htmlElementsChain/AttributesContentHandler";
import {EventsContentHandler} from "./htmlElementsChain/EventsContentHandler";

export class DomParamsHandler extends Handler {
    /**@param next {Handler}*/
    constructor(next) {
        super(next);
    }

    static #_setContent(data) {
        if (!data) return;
        let rootElement = document.querySelector("#root");
        const hasChildNodes = rootElement && rootElement.hasChildNodes();
        if (hasChildNodes) {
            const parent = rootElement && rootElement.children && rootElement.children[0];
            const savedValues = DomKeyHandler._currentValue && Object.keys(DomKeyHandler._currentValue).map(k => ({[k]: DomKeyHandler._currentValue[k]}));
            if (parent) DomParamsHandler.#_setNewDomItem([parent], savedValues);
        } else {
            document.title = isHome ? "Home" : data.title;
            rootElement.appendChild(data.page);
        }

    }
    static #_setNewDomItem(children, savedValues) {
        const eventsContentHandler = new EventsContentHandler(null);
        const attributeContentHandler = new AttributesContentHandler(eventsContentHandler)
        const textContentHandler = new TextContentHandler(attributeContentHandler);
        const htmlParamSetter = new HtmlParamsSetter(textContentHandler);
        htmlParamSetter.handle(children, savedValues);
    }

    /**@override
     * @param element {Object}
     * */
    doHandle(element) {
        element = element || {};
        DomParamsHandler.#_setContent(element);
        // last guy in the stack is the one edited.
        return false;
    }

    getName() {
        return "DomParamsHandler";
    }
}

import {Icon} from "@joloh/jspattern";

export function getStars(item) {
    let res = [];
    for (let i = 0; i < item.stars; i++) {
        res.push(Icon({id: `start${i}${(Math.random() * 2).toString().replace(".", "")}`, textContent: "star"}));
    }
    return res;
}


export function binaryText(val, arr = []) {
    let lower = 0;
    let higher = arr.length - 1;
    while (lower <= higher) {
        console.log("MAKE");
        let middle = lower + Math.floor((higher - lower) / 2);
        if (arr.indexOf(val) === middle) {
            return arr[middle];
        }
        if (arr.indexOf(val) > middle) {
            lower = middle + 1;
        } else {
            higher = middle - 1;
        }
    }
    return -1;
}

export function makeRange(arr, start, end, byTotal = false) {
    let res = [];
    start = byTotal ? start - 1 : start;
    end = byTotal ? end - 1 : end;
    let count = start;

    while (count <= end && end < arr.length) {
        res.push(arr[count]);
        count++
    }
    return res;
}

export function _binaryObject({zero, one}) {
    let binaryResult = "";
    let start = 0;

    while (start < zero) {
        binaryResult += 0;
        start++;
    }

    start = 0;
    while (start < one) {
        binaryResult += 1;
        start++;
    }

    return binaryResult;
}

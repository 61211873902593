import {Subject} from "./Subject";

// COULD CREATE AS MANY AS DATASOURCE FILES WITH EXTEND and Then turn this to abstract class and add different methods.
export class DataSource extends Subject {
    _value = {};

    setValue(value = {}) {
        Object.assign(this._value, value)
        this.notifyObservers();
    }
    getValue(){
        return this._value;
    }
}

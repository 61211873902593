import {createId} from "./helpers";

const ids = [];

for (let i = 0; i < 1000; i++) {
    ids.push(createId(32))
}

function pushNewIds() {
    ids.push(createId(32));
}

function getId(index) {
    if (!ids[index]) {
        pushNewIds();
        return ids.pop();
    }
    else return ids[index];
}

export default getId;

import {ContainerHandler} from "./";

export class HeaderClass extends ContainerHandler {
    constructor(props) {
        super(props);
    }

    /** @override **/
    getTagName() {
        return this.tagName || "h1";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }

   
    /** @override **/
    getName(){
        return "HeaderClass"
    }
}

import {State} from "./State";

export class History {
    state = State.getInstance();
    createState(value) {
        this.push(value);
        return this.state;
    }

    push(value) {
        if (this.state && !this.state.states.find(s => s.url === value.url)) this.state.states.push(value);
        else {
            const index = this.state.states.findIndex(s => s.url === value.url);
            if (index !== -1) {
                this.state.states.splice(index, 1);
                this.state.states.push(value);
            }
        }
        // console.log(this.state.states);
        // console.log("current ::: ", this.state.states[this.state.states.length -1]);
    }

    pop(){
        const current = this.state.states[this.state.states.length - 1];
        const currentIndex = this.state.states.findIndex(i => i.url === current.url);
        this.state.states.splice(currentIndex, 1);
        // const last = this.state.states[this.state.states.length - 1];
        // console.log(this.state.states);
        // console.log("popped ::: ", last);
        return this.state.states[this.state.states.length - 1];
    }
}

import {ContainerHandler} from "./";

export class ParagraphClass extends ContainerHandler {
    constructor(props) {
        super(props)
    }
    /** @override **/
    getTagName() {
        return "p";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }
   
    /** @override **/
    getName(){
        return "ParagraphClass"
    }
}

/**@interface*/
export class Subject {
    _observers = [];

    addObserver(observer){
        observer.id = Math.random() + Date.now().toString();
        this._observers.push(observer);
    }

    removeObserver(observer) {
        const index = this._observers.findIndex(o => o.id === observer.id);
        if (index > -1) this._observers.splice(index, 1);
    }

    notifyObservers() {
        for (const observer of this._observers) {
            observer.update();
        }
    }
}

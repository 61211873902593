import {DomManager} from "./DomManager";
import {ContainerInterface} from "./ContainerInterface";
import {isArray, setElementAttributes} from "../../utils/helpers";
// TODO must be able to add a new parameter in this Container
/**@abstract*/
export class ContainerHandler extends ContainerInterface {
    // self = this;
    currentValue = {};
    parameters = {};
    constructor(props) {
        super();
        props = props || {};
        const {tagName, id, className, attributes, textContent, type, style, children, innerHTML, onclick, hasObjectList = false} = props;

        this.className = className;
        this.id = id;
        this.tagName = tagName;
        this.src = props.src;
        this.type = type;
        this.textContent = textContent;
        this.onclick = onclick;
        this.innerHTML = innerHTML;
        this.children = children || [];
        this.style = style;
        this.attributes = attributes;
        this.#setDom(props);
        this.#setElement({onClick: onclick});

        if (hasObjectList) {
            this.setObjectList(this.element, props.items);
        }
    }

    _addNewParam(key, value) {
        self[key] = value;
        console.log(this);
    }

    #setDom(props) {
        this.domManager = new DomManager({
            tagName: this.tagName || this.getTagName(),
            className: this.getClassName(),
            textContent: this.textContent,
            innerHTML: this.innerHTML,
            onclick: this.onclick,
            style: this.getStyle(props) || this.style
        })
    }
    #setElement() {
        // Fix this too needs pattern
        this.element = this.domManager.getElement();
        if (this.onclick) {
            this.element.onclick = this.onclick
        }
        if (this.id) {
            this.element.id = this.id
        }
        if (this.src) {
            this.element.src = this.src
        }
        if (this.type) {
            this.element.type = this.type
        }
        if (this.attributes) {
            setElementAttributes({value: this.attributes, element: this.element});
        }

        // DESIGN PATTERN TO ADD CHAIN OF RESPOSIBILITY
    }

    getValue() {
        if (this.className) isArray(this.className) ? this.className.forEach(item => this.element.classList.add(item)) : this.element.classList.add(this.className);
        if (this.children) this.children.forEach(child => child && this.element.appendChild(child));
        return this.element;
    }
}

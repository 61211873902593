import {ButtonBox} from "./ButtonBox";
import {CardBox} from "./CardBox";
import {createId, useArgs} from "@joloh/jspattern";
import {JsPage} from "../../views/JsPage";
import {actionsTypes} from "../redux/redux";
import {addStudent} from "../classes/Api";

export class LessonElementBox  {
    constructor(option) {
        const {updateDom} = option || {};
        const [args, setArgs, addElement] = useArgs({});
        // TODO add Groupe Name -> Add Start Date -> Add Programme -> ONly one card by clicking on any card
        setArgs("parts", [
            {name: "Nom de l'apprenant", key: "lastname"},
            {name: "Prénom de l'apprenant", key: "firstname"},
            {name: "Email", key: "email"},
            {name: "Mot de passe", key: "password"},
            {name: "Description", key: "description"},
            {name: "Stars", key: "stars"}]);
        setArgs("currentIndex", 0);

        // console.log("args:::: ", args);
        this.updateDom = updateDom;
        this.cardBox = new CardBox(null, {args, setArgs, simulate: (text) => (this.simulate(text))});
        this.buttonBox = new ButtonBox();

        this.inputText = this.cardBox.CreateInput({id: "formInputText", index: 0});
        this.textInputHeader = this.cardBox.CreateInput({id: "formInputHeader", index: 0});
        this.textInputLastname = this.cardBox.CreateInput({id: "formInputLastName", index: 1});
        this.textInputfirstname = this.cardBox.CreateInput({id: "formInputFirstName", index: 2});
        this.textInputEmail = this.cardBox.CreateInput({id: "formInputEmail", index: 3});
        this.textInputPassword = this.cardBox.CreateInput({id: "formInputPassword", index: 4});
        this.textInputDescription = this.cardBox.CreateInput({id: "formInputDescription", index: 5});
        this.textInputStars = this.cardBox.CreateInput({id: "formInputStars", index: 6});

        const self = this;
        this.addListener(self.cardBox, () => this.itemsSelected());
        this.addListener(self.inputText, () => this.textChanged());
        this.addListener(self.textInputHeader, () => this.textChanged());
        this.addListener(self.textInputDescription, () => this.textChanged());
        this.addListener(self.textInputStars, () => this.textChanged());
        this.addListener(self.textInputfirstname, () => this.textChanged());
        this.addListener(self.textInputEmail, () => this.textChanged());
    }

    /**
     *  @param handler {UIControl}
     *  @param maker {Function}
     * */
    addListener(handler, maker) {
        handler.addEventHandler(new class MediatorHandlerTest {
            handle() {
                maker();
            }
        })
    }


    showNext(content, args, addElement, setArgs) {
        const doc = document.getElementById("root").children && document.getElementById("root").children[0];
        const curentForm = document.getElementById("form");
        const nextRule = args.currentIndex !== undefined && args.currentIndex < args.parts.length;
        if (curentForm) {
            setArgs("currentIndex", nextRule ? args.currentIndex + 1 : 0);
            //args.formData.items
            curentForm.remove();
        }

        this.setNextBackIcons(args);

        let children = [];
        // Build a mediator list -> steps -> descriptionBox, cardBox, starsBox, cardButtonBox / cardPicture

        // console.log("args.parts[args.currentIndex]:::   ", args.parts[args.currentIndex]);

        this.inputText.input.setAttribute("placeholder", args && args.parts && args.currentIndex !== undefined && args.parts[args.currentIndex] && args.parts[args.currentIndex].name || args.parts[0].name);
        this.inputText.input.setAttribute("name", args && args.parts && args.currentIndex !== undefined && args.parts[args.currentIndex] && args.parts[args.currentIndex].key || "");
        const form = this.inputText.form;
        // console.log("child", form);
        children.push(form);
        if (doc && doc.children && args && args.parts && args.currentIndex !== undefined && args.parts[args.currentIndex] && args.parts[args.currentIndex].key) {

            if (args.formData && args.formData.items) {
                if (form && form.parentElement) doc.style.display = "grid";
            }
        }
        Array.from(doc.children).forEach(c => children.push(c));
        children.forEach(c => doc.appendChild(c));

        let newCard = this.cardBox.AddCard(args, true, setArgs, this.updateDom, this);
        if (newCard) document.getElementById("root").appendChild(newCard);
        // console.log(args.formData);
    }

    setNextBackIcons(args) {
        const backIcon = document.getElementById("back_icon");
        const forwardIcon = document.getElementById("forward_icon");
        const hideBack = args && args['currentIndex'] > 0;
        const hideNext = args && args['currentIndex'] && args['currentIndex'] < 1 && args['currentIndex'] < args.parts.length;
        this.updateDom({element: backIcon, show: hideBack});
        this.updateDom({element: forwardIcon, show: hideNext});
    }


    simulate(text) {
        const [args, __, _] = useArgs({});
        this.cardBox.setSelection(text);
        this.inputText.setContent(text);
        // console.log("TextBox::: " + this.inputText.getContent());
        // console.log("Button::: " + this.buttonBox.isEnabled());
        const addIcon = document.getElementById("add_student");
        const clearForm = document.getElementById("clear_form");
        this.updateDom({element: addIcon, show: this.buttonBox.isEnabled() !== undefined && this.buttonBox.isEnabled()});
        this.updateDom({element: clearForm, show: this.buttonBox.isEnabled() !== undefined && this.buttonBox.isEnabled()});
        this.clearForm();
        return this.buttonBox.isEnabled();
    }

    itemsSelected() {
        this.inputText.setContent(this.cardBox.getSelection());
        this.buttonBox.setEnabled(!this.isEmpty(this.cardBox.getSelection()));
    }

    clearForm() {
        const addIcon = document.getElementById("add_student");
        const clearForm = document.getElementById("clear_form");
        const form = document.getElementById("form");
        if ((!clearForm && !addIcon ||  clearForm && clearForm.style.display === "none" && addIcon && addIcon.style.display === "none") && form) {
            form.parentElement.style.display = "block";
        } else if (form && form.parentElement) {
            form.parentElement.style.display = "grid";
        }
    }
    validate(args, setArgs, addElement, dispatch) {
        const content = args.selectedItem;
        if (content && args.parts && args.parts[args.currentIndex]) {
            if (args.currentIndex !== undefined && args.currentIndex >= 5) {
                args.formData.items.push(content);
                if (!args.students) setArgs("students", []);
                setArgs("students", args.formData.items);
                dispatch({
                    type: actionsTypes.STUDENTS,
                    students: args.students
                });
                content.roleId = 2;
                content.password = "1234";
                addStudent({user: content}).then(r => console.log(r));
            }
        } else {
            setArgs("currentIndex", 0);
        }
        // console.log("content:::: :::: :::: +++ ", args);
        this.showNext(content, args, addElement, setArgs);
        this.clearForm();
    }


    isEmpty(content) {
        return typeof content === "string" && content.length <= 0
    }

    textChanged() {
        const [args, setArgs, addElement] = useArgs({});

        const content = this.inputText.getContent();
        let key = "", value = "";

        let item = args.selectedItem ? {
            header: "",
            content: "",
            footer: "",
            url: "javascript",
            nextPage: JsPage,
            starContent: "32.52 KB",
            stars: args['stars'],
            ...args.selectedItem
        } : {
            header: "",
            content: "",
            footer: "",
            url: "javascript",
            nextPage: JsPage,
            starContent: "32.52 KB",
            stars: args['stars'],
        }
        if (this.inputText.input && this.inputText.input.name) {
            key = this.inputText.input.name;
            value = args.formText;
            if (key === "stars") {
                // console.log("stars ::::: ", args.formData.items);
                item[key] =  value !== undefined &&  !isNaN(parseInt(value)) && parseInt(value) < 7 ? parseInt(value) : 7
            } else {
                item[key] = value;
            }
            item.id = item.id || createId(12);
            setArgs('selectedItem', item);
        }

        let isEmpty = this.isEmpty(content);
        this.buttonBox.setEnabled(!isEmpty);
    }
}

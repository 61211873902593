export class DataSource {
    value;
    constructor(history) {
        this.history = history;
    }
    createState() {
        this.history.createState(this.value);
    }
    setValue(value) {
        this.value = value;
        this.createState(this.history);
    }
    getValue() {
        return this.value;
    }
    cancel() {
        this.value = this.history.pop();
    }
}

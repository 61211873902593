import {FormClass} from "../core/classes";
import {Input} from "./Input";
import {isArray, setElementAttributes} from "../utils/helpers";

export function Form(props) {
    props = props || {};

    props.children = props.items && isArray(props.items) ? props.items.map( item => {
        item.className = item.className || "input"
        const input = new Input(item);
        const option = item.attributes || {};
        const style = item.style || {};
        setElementAttributes({value: {...option, style: style}, key: null, element: input});
        return input;
    }) : props.children;
    props.items = [];

    return new FormClass(props).getValue();
}

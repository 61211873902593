import {ArgumentsHandlerInterface} from "./ArgumentsHandlerInterface";
import {_console} from "../../../utils/helpers";

// TODO
export class ArgumentsHandler extends ArgumentsHandlerInterface {
    /**@param next {ArgumentsHandlerInterface}*/
    constructor(next) {
        super(next);
    }
    /**@override*/
    doHandle({key, value, setArgs}) {
        // THIS GUY IS CALLED WITH NOTHING
        // _console(`${this.getName()} handled :::>>>`, {[key]: value});
        // what ? add or set !!!!!
        _console(key, value);
        if (typeof key !== "string") throw new Error(this.getName() + " The key must be a string");
        setArgs(key, value);
        return false;
    }
    // chain of responsibility
    setArgs(key, value) {
        // this.args[key] = value;
    }

    addElement(key, value) {
        // this.args[key].push(value);
    }
    /**@override*/
    getName() {
        return "ArgumentsHandler";
    }
}

import {ContainerHandler} from "./";

export class InputClass extends ContainerHandler {
    constructor(props) {
        super(props)
    }

    /** @override **/
    getTagName() {
        return "input";
    }
    /** @override **/
    getClassName() {
        return "input";
    }

    /** @override **/
    getStyle() {
    }

   
    /** @override **/
    getName(){
        return "InputClass"
    }
}

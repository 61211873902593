import {UIControl} from "./UiControl";
import {DomKeyHandler, setElementAttributes} from "@joloh/jspattern";
import {isObject} from "@joloh/jspattern/src/utils/helpers";

export class TextBox extends UIControl {
    constructor(content, options) {
        super();
        this.setContent(content);
    }

    setContent(content) {
        this.content = content;
        // console.warn(this.content);
        this._notifyEventHandlers();
    }

    getContent(){
        return this.content;
    }

    /**@override*/
    createElement() {
        const props = this.getContent();
        // console.error(isObject(props));
        const element = document.createElement(isObject(props) ? props.tagName : "p");
        element.textContent = isObject(props) ? props.text || props.textContent || props.innerText || props.innerHTML : props;
        if (isObject(props)) setElementAttributes({value: props, element: element});
        return element;
    }
}

import {ContainerHandler} from "./";

export class MainClass extends ContainerHandler {
    constructor(props) {
        super(props);
    }

    /** @override **/
    getTagName() {
        return "main";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }
   
    /** @override **/
    getName(){
        return "MainClass"
    }
}

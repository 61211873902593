import {UIControl} from "./UiControl";
import {Input, Section} from "@joloh/jspattern/src/components";
import {createId, Form, Grid, useArgs} from "@joloh/jspattern";
import {Card} from "../../components/Card";
import {CardList} from "../../components/CardList";
import {InputBox} from "./InputBox";

export class CardBox extends UIControl {
    constructor(selection, options) {
        super();
        this.simultate = options.simultate;
        const {args, setArgs, simulate} = options || {};
        this.args = args;
        this.setArgs = setArgs;
        this.simulate = simulate;
        this.setSelection(selection);
        this.card = this.createElement(options);
    }

    getSelection() {
        return this.selection;
    }

    CreateInput({id}) {
        const {args, setArgs, simulate} = this;
        return new InputBox(null, {
            attributes: {
                name: args && args.parts && args.currentIndex !== undefined && args.parts[args.currentIndex].key,
                placeholder: args && args.parts && args.currentIndex !== undefined && args.parts[args.currentIndex].name,
                onchange: (e) => {
                    e.target.id = id;
                    setArgs("formText", e.target.value)
                    if(e.target.value) {
                        simulate(e.target.value);
                        // console.log(e.target.value);
                    }
                },
                onblur: (e) => {
                    e.target.id = "formInput";
                    // console.log("blured");
                },
                onkeyup: (e) => {
                    setArgs("formText", e.target.value);
                    simulate(e.target.value);
                },
        }})
    }


    AddCard(show = false, updateDom, elementBox) {
        const {args, setArgs} = this;
        // Todo show add title field then show stars icon, then validate icon
        if (document.getElementById("student")) {
            const doc = document.getElementById("student");
            // console.log(document.getElementById("student").children);
            doc.remove();
            //Array.from(document.getElementById("student").children).forEach(child => {
            //
            //});
        }
        // console.log(args.parts.length);
        // console.log(args.currentIndex );
        if (args && args.parts && args.currentIndex === args.parts.length) {
            // console.log(args.formData);

            return  Section({
                id: "student",
                children: [
                    CardList({
                        items: this.getStudentItems(args),
                        updateDom: updateDom,
                        edit: args.edit,
                        createEdit: (value) => {
                            setArgs("edit", value);
                            return args.edit;
                        },
                        elementBox: elementBox,
                    }),
                ]
            });
        }
    }

    getStudentItems(args) {
        return args.formData.items.map(item => {
            item.header = `${item.firstname} ${item.lastname}`;
            return item;
        })
    }

    setSelection(selection){
        this.selection = selection;
        this._notifyEventHandlers();
    }

    /**@override*/
    createElement(props) {
        return Card({
            ...props,
            style: {
                display: "grid",
                gridTemplateRows: "1fr 2fr 1fr 1fr 1fr",
                gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))",
                gridArea: '"NAME NAME" "DESC DESC" "EMAIL PHONE" "INFO STARS" "FOOTER FOOTER"'
            },
            id: createId(12)
        })
    }
}

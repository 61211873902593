import {StoreProvider, Context} from "../patterns/state";

export const useReduxProvider = function ({reducer, initialState}) {
    const context = new Context(new StoreProvider());
    function dispatch (action) {
        context.setStore(reducer(initialState, action));
        Object.assign(initialState, context.getStore());

    }
    return [initialState, dispatch];
}

import {Handler} from "./Handler";

/**@abstract */
export class ArgumentsHandlerInterface extends Handler {
    constructor(next) {
        super(next);
    }
    // chain of responsibility
    /**@abstract*/
    setArgs() {}
    /**@abstract*/
    addElement() {}
}

import {ObserverInterface} from "./ObserverInterface";

/**@abstract*/
export class Observer extends ObserverInterface {
    // PUSH STYLE -> add parameter value // when each observer needs the same type of value
    // PULL STYLE -> observer can choose the data they need ! Best solution ! Beware of new coupling between the concrete observer and the concrete subject (subject -> observer)
    constructor() {
        super();
        this._dataSource = null;
        this._initialData = {};
    }

    getInitialData() {
        return this._initialData;
    }

    getValue(){
        return this._dataSource.getValue();
    }

    setInitialData(initialData) {
        this._initialData = initialData;
    }

    setDataSource(dataSource) {
        this._dataSource = dataSource;
    }

    getDataSource(){
        return this._dataSource;
    }
}

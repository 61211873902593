import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {JsPage} from "./JsPage";
export const SqlPage = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();


    const sql = [
        {
            header: "SQL",
            content: "",
            footer: "",
            stars: 4,
        },
        {
            header: "React JS",
            content: "",
            footer: "",
            stars: 4,
            starContent: "32.52 KB",
        },
        {
            header: "React Native",
            content: "",
            footer: "",
            stars: 3,
            starContent: "32.52 KB",
        },
        {
            header: "HTML",
            content: "",
            footer: "",
            stars: 4,
            starContent: "32.52 KB",
        },
        {
            header: "CSS",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        }
    ];

    return Section({
        children: [
            CardList({header:  "SQL", items: sql, navigation: navigation.goToUrl}),
        ]
    })
}

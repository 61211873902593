import {ContainerHandler} from "./";

export class SectionClass extends ContainerHandler {
    constructor(props) {
        super(props)
    }

    /** @override **/
    getTagName() {
        return "section";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }

    /** @override **/
    getName(){
        return "SectionClass"

    }
}

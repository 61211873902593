import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {_console, Article, Form, Grid, Icon, Span} from "@joloh/jspattern";
import {NavBar} from "./NavBar";
export const AddCvPage = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();

    class Competences {
        constructor(title, level, years_of_practice, experiences, tasks, projects, certifications) {
            this.title = title;
            this.level = level;
            this.years_of_practice = years_of_practice;
            this.experiences = experiences || [];
            this.projects = projects || [];
            this.certifications = certifications || [];
            this.tasks = tasks || [];
        }

        addTask(task) {
            if (!task) return;
            this.tasks.push(task);
        }
        removeTask(task) {
            if (!task) return;
            const index = this.tasks.findIndex(t => t.title === task.title);
            if (index > -1) this.tasks.splice(index, 1);
        }

        addExperience(exp) {
            if (!exp) return;
            this.experiences.push(exp);
        }

        addProject(proj) {
            if (!proj) return;
            this.projects.push(proj);
        }

        addCertificate(certificate) {
            if (!certificate) return;
            this.certifications.push(certificate);
        }

        removeCertificate(certificate) {
            if (!certificate) return;
            const index = this.certifications.findIndex(e => e.title === certificate.title);
            if (index > -1) this.certifications.splice(index, 1);
        }

        removeExperience(exp) {
            if (!exp) return;
            const index = this.experiences.findIndex(e => e.title === exp.title);
            if (index > -1) this.experiences.splice(index, 1);
        }

        removeProject(proj) {
            if (!proj) return;
            const index = this.projects.findIndex(e => e.title === proj.title);
            if (index > -1) this.projects.splice(index, 1);
        }
    }

    class Experiences {
        constructor(title, company, year) {
            this.title = title;
            this.year = year;
            this.company = company;
        }
    }
    const nodeCom = new Competences(
        "Node Js",
        4,
        4
    );

    nodeCom.addExperience(new Experiences());

    const jsCom = new Competences(
        "Javascript",
        5,
        6
    );
    const comps = [];

    jsCom.addExperience(new Experiences());

    const item = {};
    Object.keys(jsCom).map(k => Object.assign(item, {[k]: jsCom[k]}));

    function createCompField() {

    }
    // _console("addCompItems", item);
    const addCompForm = Form({
        items: [],
    });

    const addCompFormField = Form({
        items: [
            {name: "FieldName", attributes: {placeholder: "Champ du formulaire"}}
        ],
    });

    return Section({
        children: [
            // Header
            NavBar(params),
            Header({
                className: ["add_cv__header", "header"],
                textContent: "Ajouter cv de dev"}),
            addCompFormField,
            Span({
                className: ["nav-menu__item", "add-icon__cv-form"],
                children: [
                    Article({
                        className: "icon-container",
                        children: [
                            Icon({className: ["icon--small"] ,textContent: "post_add"})
                        ]
                    }),
                ]
            }),
        ]
    })
}

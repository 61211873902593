import {ContainerHandler} from "./";

export class IconClass extends ContainerHandler {
    constructor(props) {
        super(props);

    }

    /** @override **/
    getTagName() {
        return "span";
    }
    /** @override **/
    getClassName() {
        return "material-icons";
    }

    /** @override **/
    getStyle() {
    }

   

    /** @override **/
    getName(){
        return "IconClass"
    }
}

import {Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {JsPage} from "./JsPage";
import {NavigationHandler} from "../core/classes/NavigationHandler";
import {useArgs} from "@joloh/jspattern";
import {NavBar} from "./NavBar";



export const CVZone = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();
    const [args, setArgs, addElement] = useArgs({});

    // console.log(store);

    return Section({
        children: [
            // Header
            NavBar(params),
            CardList({header:  "Zone CV", items: store && store.students || [], navigation: navigation.goToUrl}),
        ]
    })
}

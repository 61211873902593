import { _console, isArray, isObject } from "@joloh/jspattern/src/utils/helpers";
/**@interface*/
class TableElementInterface {
  constructor(props) {
    props = props || {};
    this.tableEls = props.tableEls || {}
  }
  getStartTag(name, tableId, fine = false, classes = "") {
    let id = tableId && tableId.length > 0 ? tableId : null;
    let classNamesToUse = classes && classes.length > 0 ? classes : null;

    if (
        tableId &&
        tableId.length > 0 &&
        fine &&
        id &&
        id.length > 0 &&
        (!classNamesToUse || (classNamesToUse && classNamesToUse.length < 1))
    ) {
      return (
          this.tableEls.tagLeft +
          this.tableEls[name] +
          id +
          this.tableEls.tagRight
      );
    }
    if (
        classNamesToUse &&
        classNamesToUse.length > 0 &&
        fine &&
        (!id || (id && id.length < 1))
    ) {
      return (
          this.tableEls.tagLeft +
          this.tableEls[name] +
          ` class='${classNamesToUse}' ` +
          this.tableEls.tagRight
      );
    }
    if (
        classNamesToUse &&
        classNamesToUse.length > 0 &&
        id &&
        id.length > 0 &&
        fine
    ) {
      return (
          this.tableEls.tagLeft +
          this.tableEls[name] +
          ` class='${classNamesToUse}' ` +
          ` id=${tableId} ` +
          this.tableEls.tagRight
      );
    }

    return this.tableEls.tagLeft + this.tableEls[name] + this.tableEls.tagRight;
  }
  getCloseTag(name) {
    return (
        this.tableEls.tagClose + this.tableEls[name] + this.tableEls.tagRight
    );
  }
  giveInput(position = "", tableId = "", classes = "") {
    if (!position) return;
    const res = {
      start: this.getStartTag("table", tableId, true, classes),
      end: this.getCloseTag("table"),
      theadStart: this.getStartTag("thead"),
      theadEnd: this.getCloseTag("thead"),
      trStart: this.getStartTag("tr", tableId, true, classes),
      trEnd: this.getCloseTag("tr"),
      thStart: this.getStartTag("th", tableId, true, classes),
      thEnd: this.getCloseTag("th"),
      tdStart: this.getStartTag("td", tableId, true, classes),
      tdEnd: this.getCloseTag("td"),
      formStart: this.getStartTag("form"),
      formEnd: this.getCloseTag("form"),
      divStart: this.getStartTag("div"),
      divEnd: this.getCloseTag("div"),
    };
    // _console("res[position]", res[position]);
    // TODO return string
    return res[position] || null;
  }
  getFieldTails(value, tagName, childTagName = "") {
    let res = [];
    if (isObject(value)) {
      // Object keys
      // LOOP ON KEYS WITH A TAIL
      const keys = Object.keys(value);
      if (keys && keys.length) {
        for (let key of keys) {
          const element = value[key];
          if (isArray(element) || isObject(element)) {
            this.getFieldTails(element);
          } else {
            res.push({
              name: key,
              field: tagName,
              input:
                  this.giveInput(tagName + "Start") +
                  key +
                  this.giveInput(tagName + "End"),
              // giveInput('trStart', tableId['third'], classNames['third']) *** CHILDREN **** giveInput('trEnd')
              child: childTagName
                  ? this.giveInput(childTagName + "Start") +
                  element +
                  this.giveInput(childTagName + "End")
                  : null,
            });
          }
        }
      }
    }
    return res;
  }
  getFieldItems(values, tagName, childTagName = "", childKey = "") {
    let res = [];
    if (isObject(values)) {
      // Object keys
      //myConsole(values, 'FIELD IS OBJECT');
      // LOOP ON KEYS WITH A TAIL
      res = this.getFieldTails(values, tagName, childTagName);
    } else if (isArray(values)) {
      // Array keys
      for (let item of values) {
        // Object keys
        this.getFieldItems(item, tagName, childTagName, childKey);
      }
    } else {
      //myConsole({name: values}, 'FIELD IS PRIMITIVE FROM START');
      res.push({
        name: childKey || tagName,
        input:
            this.giveInput(tagName + "Start") +
            (childKey || values) +
            this.giveInput(tagName + "End"),
        child: childTagName
            ? this.giveInput(childTagName + "Start") +
            values +
            this.giveInput(childTagName + "End")
            : null,
      });
      // DO SOMETHING
    }
    return res;
  }
  createField(tagName, childTagName = "", items = [], childKeyForSingle = "") {
    return {
      name: tagName,
      items: this.getFieldItems(
          items,
          tagName,
          childTagName,
          childKeyForSingle
      ),
    };
  }
  createTableItemHtmlTags(
      item = "",
      classes = null,
      tableId = null,
      options = null
  ) {
    // TODO regex could be argument
    let tag = "<thead><tr";
    let regex = /(<thead><tr>)+/g;

    if (
        (classes && typeof classes !== "string") ||
        (tableId && typeof tableId !== "string") ||
        (options && typeof options !== "string")
    ) {
      return;
    }

    if (classes && !tableId) {
      item = item && item.replace(regex, `${tag} class='${classes}'>`);
    } else if (tableId) {
      item = item && item.replace(regex, `${tag} id='${tableId}'>`);
    } else if (classes && tableId) {
      item = item && item.replace(regex, `${tag} id='${tableId} class='${classes}'>`);
    }
    if (
        options &&
        tableId &&
        tableId.length > 0 &&
        classes &&
        classes.length > 0
    ) {
      item = item && item.replace(
          regex,
          `${tag} id='${tableId} class='${classes}'> ${options}`
      );
    }

    return item;
  }
}
export class TableCreator extends TableElementInterface {
  constructor(cartItems, title) {
    super();
    this._title = title || "";
    this._fields = cartItems || [];
  }
  hiddenButtonPages = ["order", "user", "food", "promo"];
  tableEls = {
    tagLeft: "<",
    tagRight: ">",
    tagClose: "</",
    table: "table",
    form: "form",
    div: "div",
    thead: "thead",
    th: "th",
    tr: "tr",
    td: "td",
  };
  classNames = {
    first: "mdc-data-table__table cart-content",
    third: "",
    theadStart: "mdc-data-table__header-row",
    th: "mdc-data-table__header-cell",
    td: "mdc-data-table__cell",
    childParent: "mdc-data-table__row",
    child: "mdc-data-table__row",
    rowTextCell: "mdc-data-table__cell mdc-data-table__cell",
    rowNumCell: "mdc-data-table__cell mdc-data-table__cell--numeric",
    headerTextCell: "mdc-data-table__header-cell mdc-data-table__header-cell",
    headerNumCell:
      "mdc-data-table__header-cell mdc-data-table__header-cell--numeric",
  };
  getTitle() {
    return this._title;
  }
  // TODO RENAME THIS FUNCTION
  createHeadOrChild(items = [], classes = {}, childId = {}, dataset = {}, isTableChild = false) {
    let headers = "",
      isInteger = false,
      children = "",
      classToUse = "";

    for (let item of items) {
      if (classes) {
        // is Numeric
        const regexRes = item.child && item.child.replace(/(<|td|\/|>)+/g, "");
        isInteger =
          regexRes &&
          regexRes.length > 0 &&
          typeof regexRes === "string" &&
          !isNaN(parseInt(regexRes))
            ? typeof JSON.parse(regexRes) === "number"
            : false;

        if (isInteger) {
          // TODO THESE CLASS NAMES SHOULD BE PARAMETERS
          classToUse = isTableChild
            ? "mdc-data-table__cell mdc-data-table__cell--numeric"
            : "mdc-data-table__header-cell mdc-data-table__header-cell--numeric";
        } else {
          classToUse = isTableChild ? classes["td"] : classes["th"];
        }
        // IF IT'S HEADER items[i].input OR CHILDREN items[i].child
        if (isTableChild && item.child) {
          item.child = item.child.replace(
            /(<td>)+/g,
            `<td class="${classToUse}">`
          );
        } else {
          item.input = item.input.replace(
            /(<th>)+/g,
            `<th class="${classToUse}">`
          );
        }
      }
      // IF IT'S HEADER items[i].input OR CHILDREN items[i].child
      if (isTableChild) {
        children += item.child;
      } else {
        headers += item.input;
      }
    }
    return isTableChild ? children : headers;
  }
  showCartContent() {
    if (this._fields && this._fields.length > 0) {
      return this.makeHtmlTable(this._fields, this.classNames);
    }
    return null;
  }
  createTablePart(items = [], tableId = {}, classes = {}) {


    if (!isArray(items) || !isObject(tableId) || !isObject(classes)) {
      return;
    }

    let first =
      this.giveInput("start", tableId["first"], classes["first"]) +
      this.giveInput(
        "theadStart",
        tableId["theadStart"],
        classes["theadStart"]
      ) +
      this.giveInput("trStart", tableId["third"], classes["third"]);

    // _console("first ---------", first);

    const third =
      this.giveInput("trEnd") +
      this.giveInput("theadEnd") +
      this.giveInput("trStart", tableId["third"], classes["third"]);
    const last = this.giveInput("trEnd") + this.giveInput("end");

    if (classes["theadStart"] && !tableId["theadStart"]) {
      // TODO USE THIS FUNCTION INSTEAD
      first = this.createTableItemHtmlTags(
        first,
        classes["theadStart"],
        tableId["theadStart"]
      );
    } else if (tableId["theadStart"]) {
      first = third.replace(
        /(<thead><tr>)+/g,
        `<thead><tr id='${tableId["theadStart"]}'>`
      );
    } else if (classes["theadStart"] && tableId["theadStart"]) {
      // TODO add id and classNames and other tags
      first = first.replace(
        /(<thead><tr>)+/g,
        `<thead><tr id='${tableId["theadStart"]}' class='${classes["theadStart"]}'>`
      );
    }

    return [first, third, last].length > 0
      ? { first: first, third: third, last: last }
      : [];
  }
  makeHtmlTable(items = [], classes = {}) {
    let children = "",
      headers = [],
      first,
      third,
      last,
      tableObjs = {};


    items.forEach((item, index) => {
      tableObjs = this.createField("th", "td", item);
      // _console("tableobj", tableObjs);
      //const tableOneField = createField('form','td', 'avion', 'lastName');
      headers.push(this.createHeadOrChild(tableObjs.items, { th: classes.th }));
      // giveInput('trStart', tableId['third'], classNames['third']) *** CHILDREN **** giveInput('trEnd')
      const child = this.createHeadOrChild(
        tableObjs.items,
        { td: classes.td },
        null,
        null,
        true
      );

      if (child && child.length > 0 && typeof child === "string") {
        children +=
          this.giveInput("trStart", null, classes.child) +
          child +
          this.giveInput("trEnd");
      }
    });
    //

    first = this.createTablePart(
      tableObjs.items,
      {},
      {
        first: "mdc-data-table__table cart-content",
        theadStart: "mdc-data-table__header-row",
      }
    )["first"];
    // _console("thirst oooooooo", first);
    third = this.createTablePart(tableObjs.items, {}, { third: "" })["third"];
    // third = new TablePart({name: "third", tableObjs, classes: { third: "" }, tableId: {}}).getValue("third");
    last = this.createTablePart(tableObjs.items)["last"];
    // last = new TablePart({name: "last", tableObjs, classes: {}, tableId: {}}).getValue("last");
    // _console("table value", {header: headers[0], first: first,  third: third, children: children, last: last});
    let innerHtml = first + headers[0] + third + `${children}` + last;

    let emptyElement = new RegExp(/(<tr>)+(<\/tr>)+/g);
    innerHtml = innerHtml.replace(emptyElement, "");
    const child = document.createElement("span");
    child.innerHTML = innerHtml

    return child;
  }
}

export class HtmlParamsSetter {
    /**@param handler {HTMLHandler}*/
    constructor(handler) {
        this.handler = handler;
    }

    handle(children, savedValues) {
        if (this.handler) this.handler.handle(children, savedValues);
    }
}

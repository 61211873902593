import {EventsContentHandler} from "@joloh/jspattern/src/core/patterns/chain/htmlElementsChain/EventsContentHandler";
import {AttributesContentHandler} from "@joloh/jspattern/src/core/patterns/chain/htmlElementsChain/AttributesContentHandler";
import {TextContentHandler} from "@joloh/jspattern/src/core/patterns/chain/htmlElementsChain/TextContentHandler";
import {HtmlParamsSetter} from "@joloh/jspattern/src/core/patterns/chain/htmlElementsChain/HtmlParamsSetter";

import {
    DomKeyHandler,
    DomParamsHandler,
    DomUpdateHandler,
    PageParamsHandler,
    PageSetter,
    Navigator,
    PageUpdateSetter, StateObserver, isHome
} from "@joloh/jspattern";

/**@interface*/
class UIControlInterface {
    /**@abstract*/
    createElement(props) {}
}

/**@abstract*/
export class UIControl extends UIControlInterface {
    constructor() {
        super();
        /**@type {Array<MediatorEventHandler>} */
        this._eventHandler = [];
    }

    addEventHandler(handler) {
        this._eventHandler.push(handler)
    }

    createChild(option) {
        // console.log(option);
        // console.log("DomKeyHandler currentValue", DomKeyHandler._currentValue)
        const {navigation, initialState, reducer, stateObserver} = option || {};
        const routes = option && option.title && {[option.title.toString().toLowerCase()] : null};
        const params = option;
        // console.log("params :::: params ::::: ", params);
        let element = document.createElement( "div");
        const hasBody = params.body;
        const hasChildren = params.children;
        if (hasBody) {
            if ( typeof params.body === "string" ) element.innerHTML = params.body;
            else element.appendChild(params.body);
        } else if (hasChildren) {
            Array.from(params.children).forEach(item => {
                // console.log(item);
                // chain
                let child = item;
                const isText = typeof item === "string";
                if (isText) {
                    child = document.createElement("p");
                    child.textContent = item.toString();
                }
                element.appendChild(child);

            })
        }
        return element;
    }


    _notifyEventHandlers() {
        for (let i = 0; i < this._eventHandler.length; i++) {
            this._eventHandler[i].handle();
        }
    }

    setAttributes(attributeHandler) {

    }

    setMethods(methodsHandler) {

    }
}

import {Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {useArgs} from "@joloh/jspattern";
import {NavBar} from "./NavBar";
import {binaryText, makeRange} from "../utils/helpers";
import {actionsTypes} from "../core/redux/redux";
import {getStudents} from "../core/classes/Api";


let savedValue = {};
let savedFinal = {};

export const ClassRoom = (params)  => {

    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();
    const [args, setArgs, addElement] = useArgs({});

    getStudents(setArgs, dispatch).then(r => r);

    let students = store.students || localStorage.getItem('students') && JSON.parse(localStorage.getItem('students'));

    // console.log(students);
    return Section({
        children: [
            // Header
            NavBar(params),
            CardList({header:  "Les classes", items: students && students.length > 0 && students.map(s => {
                    s.header = `${s.lastname || ""} ${s.firstname || ""}`;
                    s.description = `${s.description || ""}`;
                    return s;
                }) || store && store.students || [], navigation: navigation.goToUrl}),
        ]
    })
}

import {HTMLHandler} from "../Handler";
import {AttributesIterator, useIterator} from "../../iterator";
import {setElementAttributes} from "../../../../utils/helpers";

/**@abstract*/
class EventHandlerInterface {
    /**
     * @abstract
     * */
    handle(option){

    }
    /**@abstract
     * @return boolean
     * */
    doHandle(option){}
}
/**@abstract*/
class EventsHandler extends EventHandlerInterface {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super();
        this._next = next;
    }

    handle(option) {
        if (this.doHandle(option)) {
            return;
        }
        if (this._next) {
            return this._next.handle(option)
        }
    }

    iteratorCallback({savedElement, key, child}) {
        if (!savedElement || !key || !child) return;
        const foundMethod = savedElement[key];
        if (foundMethod) {
            child[key] = foundMethod;
            setElementAttributes({value: {[key]: foundMethod}, element: child});
        }
    }

    getName() {
        return "EventsHandler";
    }
}
class DragHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }

    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["ondrag", "ondragenter", "ondragstart", "ondragleave"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "DragHandler";
    }
}
class DropHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }
    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["ondrop"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "DropHandler";
    }
}
class ClickHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }
    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onclick", "ondblclick", "onauxclick"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "ClickHandler";
    }
}
class KeyBoardHandler extends EventsHandler {
    /**@param next {EventsHandler}
     * */
    constructor(next) {
        super(next);
    }

    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onkeyup", "onkeydown", "onkeypress"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }
    getName() {
        return "KeyBoardHandler";
    }
}
class BlurHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }
    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onblur"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "BlurHandler";
    }
}
class MouseHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }
    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onmousedown","onmouseup","onmouseenter","onmouseleave","onmouseover",]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "MouseHandler";
    }
}
class SelectHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }
    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onselect","onselectstart"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "SelectHandler";
    }
}
class ChangeHandler extends EventsHandler {
    /**@param next {EventsHandler}*/
    constructor(next) {
        super(next);
    }

    doHandle(option) {
        option = option || {};
        const {savedElement, child} = option;
        const iter = new AttributesIterator();
        iter.browser.set(["onchange"]);
        useIterator({iterator: iter, callback: (current) => this.iteratorCallback({savedElement, key: current, child})});
        return false;
    }

    getName() {
        return "ChangeHandler";
    }
}

export class ElementEventManager {
    /**@param handler {EventHandlerInterface} */
    constructor(handler) {
        this._handler = handler;
    }

    handle(option) {
        if (this._handler) this._handler.handle(option);
    }
}
export class EventsContentHandler extends HTMLHandler {
    static result = [];
    /**@param next {HTMLHandler}*/
    constructor(next) {
        super(next);
    }

    static #_checkEventsContent(children, savedValues, _iteratorCallback) {
        const iterator = new AttributesIterator();
        iterator.browser.set(children);
        useIterator({iterator, callback: (child) => _iteratorCallback({child, savedValues})});
    }

    static #_iteratorCallback({child, savedValues}){
        let existingData = savedValues.find((item) => {
            return Object.keys(item)[0] === child.dataset.domKey
        });

        const element = existingData && existingData[Object.keys(existingData)[0]];

        if (element && element.dataset && element.dataset.domKey === child.dataset.domKey) {
            const drag = new DragHandler(null);
            const keyboard = new KeyBoardHandler(drag);
            const blur = new BlurHandler(keyboard);
            const drop = new DropHandler(blur);
            const click = new ClickHandler(drop);
            const mouse = new MouseHandler(click);
            const change = new ChangeHandler(mouse);
            const select = new SelectHandler(change);
            const eventManager = new ElementEventManager(select);
            eventManager.handle({savedElement: element, child});
        }

        if (child.children) {
            EventsContentHandler.#_checkEventsContent(Array.from(child.children), savedValues, EventsContentHandler.#_iteratorCallback);
        }
    }

    /**@override
     * @param children
     * @param savedValues
     * */
    doHandle(children, savedValues) {
        if (!children || !savedValues) return;
        EventsContentHandler.#_checkEventsContent(children, savedValues, EventsContentHandler.#_iteratorCallback);
        if (EventsContentHandler.result && EventsContentHandler.result.length > 0) {
            EventsContentHandler.result.splice(0);
        }
        return false;
    }

    getName() {
        return "EventsContentHandler";
    }
}


import {Provider} from "./Provider";
import {_console} from "../../../utils/helpers";

export class StoreProvider extends Provider {
    /**@param state {Object}*/
    setStore(state = {}) {
        // console.log("StoreProvider setStore ok");
        // _console("setStore", {state});
        this._value = state;
    }

    getStore() {
        return this._value;
    }
}

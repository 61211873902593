import {getStudents} from "../classes/Api";

export const initialState = {
  user: {},
  users: [],
  toto: [],
  product: {},
  products: [],
  students: [],
};
export let singleState = {};

export const actionsTypes = {
  PRODUCTS: "PRODUCTS",
  PRODUCT: "PRODUCT",
  USER: "USER",
  STUDENTS: "STUDENTS",
  USERS: "USERS",
};

const reducer = (state = {}, action = {}) => {
  switch (action.type) {
    case actionsTypes.USER:
      return {
        ...state,
        user: action.user,
      };
    case actionsTypes.PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case actionsTypes.PRODUCTS:
      return {
        ...state,
        products: action.products,
      };
    case actionsTypes.STUDENTS:
      return {
        ...state,
        students: action.students,
      };
    case actionsTypes.USERS:
      return {
        ...state,
        users: action.users,
      };
    default:
      return state;
  }
};

export function createSingleStateValue(value, id) {
  singleState[id] = value;
  return singleState[id];
}

export default reducer;

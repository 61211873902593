import {Section} from "@joloh/jspattern/src/components";
import {isArray} from "@joloh/jspattern/src/utils/helpers";

export const Card = (props) => {
    props = props || {};
    const arrayClass = props.className && isArray(props.className) ? ["card", ...props.className] : props.className || ["card"]


    return  Section({
        ...props,
        className: arrayClass,
        children: props.children || []
    })
}

import {Article, createId, Grid, Header, Icon, Paragraph, Section, Span, useArgs} from "@joloh/jspattern";
import {Card} from "./Card";
import {ArrowLink} from "./ArrowLink";
import {JsPage} from "../views/JsPage";
import {getStars} from "../utils/helpers";

// class HeaderModel {
//     constructor(props) {
//         this.props = props;
//     }
//     data(){
//         return {
//             text : this.props.header,
//         }
//     }
// }

// class ContentModel {
//     constructor(props) {
//         this.props = props;
//     }
//     data(){
//         return {
//             text : "Rien de plus frustrant que de devoir revoir une bonne partie du code existant lorn",
//         }
//     }
// }

// class FooterModel {
//     constructor(props) {
//         this.props = props;
//     }
//     data(){
//       return {
//           buttonText : "En savoir plus",
//           iconText : "32.53",
//           stars: 6
//       }
//     }
// }



export function CardList(props) {
    props = props || {};
    const {items} = props
    let {navigation, updateDom, elementBox, edit, createEdit, addElements} = props;
    //const {args, setArgs, addElements} = useArgs && useArgs({}) || {};

    // console.log("navigationxxxxxx:::: ", typeof navigation);
    // console.log("navigationxxxxxx:::: ", edit);

    let editHeader = null;
    let editDescription = null;
    let editStars = null;

    return  Section({
        className: ["card-list"],
        children: [
            Header({className: ["header", "card-list__header"],textContent: props.header}),
            Grid({
                gtc: "repeat(auto-fit, minmax(400px, 1fr))",
                cg: "2rem",
                gap: "2rem",
                children: items.map(item => {
                    // navigation = item.navigation || navigation;
                    const itemHasNavigation = item.url !== undefined && item.url !== null;
                    const cardIconsId = createId(12);
                    const cardId = createId(12);
                    return Card({
                        id: cardId,
                        onclick:(e) => {

                            if (itemHasNavigation && navigation) {
                                navigation({...item, title: item.header});
                            } else if (navigation) {
                                // console.log("oooook")
                                // navigation({title: item.header});
                                // e.target.classList.add("card-list__item__clicked");
                                // e.target.parentElement.
                                // console.log("item clicked:    ", e.target);
                            } else {
                                // console.log("cardId:::: onclick card", cardId);
                            }
                        },
                        attributes: {style: props.style || {}},
                        className: ["card-list__item","hover-animation", "showcase__content", "alternate-background"],
                        children: [
                            editHeader || Header({
                                className: ["mb2", "s", "blue-secondary"],
                                tagName:  "h6", textContent: item.header || item.studentName}),
                            editDescription || Paragraph({textContent: item.description || "Rien de plus frustrant que de devoir revoir une bonne partie du code existant lorn"}),
                            Grid({
                                //gtc: "1fr 1fr",
                                gtc: "repeat(auto-fit, minmax(15rem, 1fr))",
                                style: {padding: "4rem 0 0"},
                                children: [
                                    ArrowLink({
                                        attributes: {style: {textAlignLast: "start", alignSelf: "center"}},
                                        onclick: () => {},

                                        children: [
                                            Grid({
                                                gtc: "3fr 16fr",
                                                className: ["arrowed-link"],
                                                ai: "center",
                                                children: [
                                                    Span({
                                                        tagName: "span",
                                                        className: ["arrow__text", "blue-secondary"],
                                                        textContent: "En savoir plus"
                                                    }),
                                                    Icon({
                                                        tagName: "span",
                                                        className: ["arrow__icon", "blue-secondary"],
                                                        textContent: "east"
                                                    })
                                            ]})
                                        ],
                                    }),
                                    Section({
                                        style: {padding: "0.5rem 0 0", gridColumnEnd: "none"},
                                        children: editStars || [...getStars(item)]
                                    }),
                                ]
                            }),
                            // hide or with click on card.
                            Section({
                                style: {display: "flex"},
                                children: [
                                    Section({
                                        id: "card_tune_icon" + `${Math.random() * 2}`,
                                        style: {transition: "all 0.3s"},
                                        children: [
                                            Article({
                                                style: {margin: "unset", padding: "0.99rem 0 0 0"},
                                                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                                                onclick: (e) => {
                                                    if (updateDom) {
                                                        // console.log("clicked", e.target.parentElement);
                                                        const card_icons = document.getElementById(cardIconsId);
                                                        const show = !card_icons.style.display || card_icons.style.display === "block" ? "none" : "block";
                                                        updateDom({element: card_icons, show: show === "block"});
                                                    }
                                                },
                                                children: [
                                                    Article({
                                                        className: "icon-container",
                                                        children: [
                                                            Icon({className: ["icon--small"] ,textContent: "tune"})
                                                        ]
                                                    }),
                                                ]
                                            }),
                                        ]
                                    }),
                                    Section({
                                        style: {display: "none"},
                                        id: cardIconsId,
                                        children: [
                                            Article({
                                                style: {display: "flex"},
                                                children: [
                                                    Section({
                                                        id: "card_add_icon",
                                                        style: {transition: "all 0.3s"},
                                                        children: [
                                                            Article({
                                                                style: {margin: "0 0 0 1rem", padding: "0.99rem 0 0 0"},
                                                                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                                                                onclick: (e) => {
                                                                    if (!edit && createEdit) edit = createEdit({stars: {}, description: {}, header: {}});
                                                                    let _edit = edit;
                                                                    _edit.stars[cardId] = !_edit.stars[cardId];
                                                                    _edit.description[cardId] = !_edit.description[cardId];
                                                                    _edit.header[cardId] = !_edit.header[cardId];
                                                                    edit = createEdit(_edit);
                                                                    //createEdit({stars: !args.edit.stars || args.edit.stars === false, description: false, header: false});
                                                                    editHeader = edit && edit.header[cardId] && elementBox.textInputHeader;
                                                                    editDescription = edit && edit.description[cardId] && elementBox.textInputDescription;
                                                                    editStars = edit && edit.stars[cardId] && elementBox.textInputStars;
                                                                    // console.log("edit:::: ", _edit);
                                                                    // console.log("editHeader:::: ", editHeader);
                                                                    // console.log("editDescription:::: ", editDescription);
                                                                    // console.log("editStars:::: ", editStars);
                                                                    // console.log("editStars:::: elementBox.textInputStars", elementBox.textInputStars);
                                                                },
                                                                children: [
                                                                    Article({
                                                                        className: "icon-container",
                                                                        children: [
                                                                            Icon({className: ["icon--small"] ,textContent: "add_circle"})
                                                                        ]
                                                                    }),
                                                                ]
                                                            }),
                                                        ]
                                                    }),
                                                    Section({
                                                        id: "card_delete_icon",
                                                        style: {transition: "all 0.3s"},
                                                        children: [
                                                            Article({
                                                                style: {margin: "0 0 0 1rem", padding: "0.99rem 0 0 0"},
                                                                className: ["nav-menu__item",  "add-icon__cv-form", "alternate-visibility"],
                                                                onclick: (e) => {

                                                                },
                                                                children: [
                                                                    Article({
                                                                        className: "icon-container",
                                                                        children: [
                                                                            Icon({className: ["icon--small"] ,textContent: "delete_forever"})
                                                                        ]
                                                                    }),
                                                                ]
                                                            }),
                                                        ]
                                                    }),
                                                ]
                                            })
                                        ]
                                    })
                                ]
                            }),
                        ]
                    })
                }),
            })
        ]
    });
}

import {isArray} from "@joloh/jspattern/src/utils/helpers";
import {actionsTypes} from "../redux/redux";

export async function addStudent(student) {
    console.log("student to add::: ", student);
    const data = await fetch("http://localhost:3011/api/new_student", {
        headers: {},
        method: "post",
        body: JSON.stringify({data: student})
    });
    const res = data && await data.json()
    //console.log(res);
}

export async function getStudents(setArgs, dispatch) {
    // const data = await fetch("http://localhost:3011/api/students", {
    //     method: "post",
    // });

    // const res = data && await data.json();
    const res = [];

    setArgs("students", res || []);
    dispatch({
        type: actionsTypes.STUDENTS,
        students: res
    })
    return res;
}


/**@interface*/
export class Provider {
  /**
   * @abstract
   *@param state {Object}
   *
   * */
  setStore(state){}
  /**@abstract*/
  getStore(){}
}

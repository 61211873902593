// import {HandlerInterface} from "../../classes";
// import {HTMLHandlerInterface} from "../../classes/HandlerInterface";

/**@interface*/
export class HandlerInterface {
    /**@abstract
     * */
    handle(value){}
    /**
     * @abstract
     * @return boolean
     * */
    doHandle(value){}
    /**
     * @abstract
     * @return string
     * */
    getName(){}
}
/**@interface*/
export class HTMLHandlerInterface {
    /**@abstract
     * */
    handle(value){}
    /**
     * @abstract
     * @return boolean
     * */
    doHandle(value, compared){}
    /**
     * @abstract
     * @return string
     * */
    getName(){}
}

/**@abstract*/
export class Handler extends HandlerInterface {
    /**@param next {Handler} */
    constructor(next) {
        super();
        this._next = next;

    }

    handle(value){
        if (this.doHandle(value))
            return;
        if (this._next)
            this._next.handle(value);
    }
}

/**@abstract*/
export class HTMLHandler extends HTMLHandlerInterface {
    /**@param next {HTMLHandler} */
    constructor(next) {
        super();
        this._next = next;

    }
    handle(value, compared){
        if (this.doHandle(value, compared))
            return;
        if (this._next)
            this._next.handle(value, compared);
    }
}

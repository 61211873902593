import {ContainerHandler} from "./";

export class ButtonClass extends ContainerHandler {
    constructor(props) {
        super(props);
    }

    /** @override **/
    getTagName() {
        return "button";
    }
    /** @override **/
    getClassName() {
        return "";
    }

    getStyle() {
    }

    /** @override **/
    getName(){
        return "ButtonClass"
    }
}

/**
 * @param currentValue
 *
 * */
import {Arg} from "./Arg";
import {ArgumentsProvider} from "./ArgumentsProvider";

export const useArgs = function () {
    // INITIALIZING THE ARG FUNCTION
    Arg({});
    return [ArgumentsProvider.args, ArgumentsProvider.setArgs, ArgumentsProvider.addElement];
}

export const isHome =
  location.pathname === "/" || location.pathname === "/home";

export const pageName = isHome
  ? "home"
  : location.pathname.substr(location.pathname.indexOf("/") + 1);

export const pageTitle = pageName
  ? pageName.substr(0, 1).toUpperCase() + pageName.substr(1)
  : "";

export const isObject = function (value) {
  return value && Array.prototype.toString.call(value).includes("object Object");
};

export const isContainerHandler = function (value) {
  return value && Array.prototype.toString.call(value).includes("object isContainerHandler");
};

export const isHtmlInputElement = function (value) {
  return value && Array.prototype.toString.call(value).includes("object HTMLInputElement");
};
export const isFunction = function (value) {
  return value && Array.prototype.toString.call(value).includes("object Function");
};

export const isHtmlElement = function (value) {
  return value && Array.prototype.toString.call(value).includes("object HtmlElement");
};

export const isArray = function (value) {
  return value && Array.isArray(value);
};

export function _console(name, data) {
  name = name && typeof name === "string" ? name : "LOG DETAILS";
  console.log("---------", name.toUpperCase(), "------------");
  console.log(data);
  console.log("-------------*******************--------------");
}

export function setElementAttributes({value, key, element}) {
  const rule = (value !== undefined && element && (isObject(value) && Object.keys(value).length > 0 || value));
  if (rule) {
    if (key === "style" && isObject(value))
      tail(key, value);
    else {
      Object.keys(value).forEach(k => {
        if (typeof value[k] === "function") {
          element[k] = value[k];
        } else {
          tail(k, value[k]);
        }
      });
    }

    function tail(k, _value) {
      if (typeof _value === "function") {
        element[k] = value;
      } else {
        if (k === "style") {
          Object.keys(_value).map(styleKey => element.style[styleKey] = _value[styleKey]);
        } else if (!isObject(_value)) {
          if (k.toLowerCase() === "classname") k = "class";
          element.setAttribute(k, _value);
        }
      }
    }
  }
  return this;
}


function _limiter(str, limit) {
  return str && str.length >= limit;
}

// DO NOT TOUCH UNLESS YOU KNOW WHAT YOU ARE DOING
export function createId(limit) {
  let valueStr = "0123456789";

  if (_limiter(limit)) {
    return;
  }

  let value = "";
  let str = "";
  while (str.length < 32) {
    let rndNum = (Math.random() * 32).toString().replace(".", "");
    Array.from(rndNum).forEach((cursor, index) => {
      cursor = parseInt(cursor);
      value = cursor % 2 === 0 ? valueStr[cursor].toLowerCase() :   valueStr[cursor];
      if (str.length < 32) {
        str += value;
      }
    });
  }
  return str;
}

export function ucFirst(str) {
  if (!str || typeof str !== "string" || str.length < 1) {
    _console("ucFirst Error", Error("Str should be at least two characters long"))
    return;
  }
  return str.substr(0, 1).toUpperCase() + str.substr(1);
}

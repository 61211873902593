import {Button, Header, Paragraph, Section} from "@joloh/jspattern/src/components";
import {CardList} from "../components/CardList";
import {NavigationHandler} from "../core/classes/NavigationHandler";
export const JsPage = (params)  => {
    const {stateObserver, useState, navigation, useReduxProvider, updateDom} = params({
        message: "hello world",
    });

    const [store, dispatch] = useReduxProvider();

    const jsList = [
        {
            header: "Polymorphisme",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Encapsulation",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 3,
        },
        {
            header: "Abstraction",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Classes et Héritages",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Design Pattern",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "DOM",
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
    ];

    // const navHandler = new NavigationHandler({navigation, stateObserver, useState});
    return Section({
        children: [
            CardList({header:  "Javascript", items: jsList, navigation: navigation.goToUrl}),
        ]
    })
}

import {
    Article,
    Button,
    DefinitionList,
    DefinitionListDescription,
    DefinitionListTerme,
    Figure,
    Form,
    Grid,
    Header,
    Icon,
    List,
    ListItem,
    Paragraph,
    Section,
    Image,
    Table,
    DataCell,
    TableBody,
    TableRow,
    TableFooter,
    TableHeader,
    IconButton,
    Span,
    StateManager,
    useArgs, Arg, pageTitle
} from "@joloh/jspattern";
import {_console, setElementAttributes} from "@joloh/jspattern";
import {actionsTypes} from "../core/redux/redux";
import * as img from "../../assets/img.png"
import * as SearchIcon from "../../assets/icons/search.svg"
import {Contact} from "./Contact";
import {TableCreator} from "../core/classes/TableCreator";
import {Docs} from "./Docs";
import {visitedPages} from "../index";
import {Terminal} from "../components/Terminal";
import {Card} from "../components/Card";
import {ArrowLink} from "../components/ArrowLink";
import {ListElementBox} from "../core/mediator/ListElementBox";
import {DeclarationExpression, VisitorManager} from "../core/classes/Compilator";
import {CardList} from "../components/CardList";
import {UmlPage} from "./UmlPage";
import {GraphQlPage} from "./GraphQlPage";
import {SqlPage} from "./SqlPage";
import {HtmlPage} from "./HtmlPage";
import {FlutterPage} from "./FlutterPage";
import {PhpPage} from "./PhpPage";
import {JavaPage} from "./JavaPage";
import {ReactNativePage} from "./ReactNativePage";
import {ReactJsPage} from "./ReactJsPage";
import {NodePage} from "./NodePage";
import {JsPage} from "./JsPage";
import {CssLibsPage} from "./CssLibsPage";
import {CssPage} from "./CssPage";
import {NavBar} from "./NavBar";
import {AddCvPage} from "./AddCvPage";
import {DataSource} from "../core/memento/DataSource";
import {History} from "../core/memento/History";

export const Home = (params) => {
    let tableItems = [
        { id: 1, name: "ok", title: "jack", unit_price: 25, price: 125, qty: 1 },
        { id: 2, name: "tomate", title: "same", unit_price: 25, total: 25, qty: 1 },
    ];
    const tableCreator = new TableCreator(tableItems, "Auto Table");
    const {stateObserver, useState, navigation, useReduxProvider, updateDom, dataSource} = params({
        text: "text",
        image: null
    });

    const setText = useState("text");
    const setIsOn = useState("isOn");
    const setImage = useState('image');
    const [store, dispatch] = useReduxProvider();
    const [args, setArgs, addElement] = useArgs();
    function okKeydown(e) {
        console.log(e);
    }


Arg("test", true);

    const formItems = {
        fields: [
            {name: "name", tagName: "input", attributes: {placeholder: "Name", type: "text", className: "input", style: {background: "aliceblue"}, onkeydown: okKeydown}},
            {name: "placeholder", tagName: "input", attributes: {placeholder: "Placeholder", type: "text", className: "input", style: {background: "aliceblue"}, onkeydown: okKeydown}},
            {name: "type",tagName: "input", attributes: {placeholder: "Type", type: "text", className: "input", style: {background: "aliceblue"}, onkeydown: okKeydown}},
            {name: "content", tagName: "input", attributes: {placeholder: "Content", type: "text", className: "input", style: {background: "aliceblue"}, onkeydown: okKeydown}},
        ],
    }


    const tableItemsForm = {
        fields: [
            {name: "header", value: "Name"},
        ],
        props: {},
    }

    const htmlCode = `<?prettify linenums=1?>
<pre class="prettyprint lang-html">
<code class="html"><xmp class="prettyprint" id="htmlXmp"><html lang="fr">
    <head>
        <title>${tableCreator.getTitle()}</title>
    </head>
    <body>
       <section>Hello</section>
    </body>
</html>
</xmp>
</code></pre>`;
    const htmlStartCode = `<?prettify linenums=1"?>
<pre class="prettyprint lang-html">
<code class="html"><xmp class="prettyprint" id="htmlXmp"><!DOCTYPE html>
<html lang="en">
<body>
 <main id="root"></main>
 <script type="module" src="src/index.js"></script>
</body>
</html>
</xmp></code></pre>`;

    const cssCode = `<?prettify linenums=1?>
<pre class="prettyprint">
<code class="css">.user {
    margin: 1rem;
}
</code></pre>`;

    const jsCode = `<?prettify linenums=1?>
<pre class="prettyprint"><code class="js">class User {
    // read more <a href="#">more...</a>.
    const a = "2";
}
</code></pre>`;

    const codeImport = `<?prettify linenums=1?>
<pre class="prettyprint"><code class="js">import {
    Icon,
    Paragraph,
    Section
} from "@joloh/jspattern/src/components"

Section({
    className: "arrowed-link",
    onclick: () => {},
    children: [
        Paragraph({
            tagName: "span",
            className: "arrow__text",
            textContent: "En savoir plus"
        }),
        Icon({
            tagName: "span",
            className: "arrow__icon",
            textContent: "east"
        })
    ]
})
</code></pre>`;

    const buttonCode = `<?prettify linenums=1?>
<pre class="prettyprint"><code class="js">IconButton({
    children: [
        Span({textContent: "easy"}),
        Icon({textContent: "person"}),
    ],
})
</code></pre>`;

    // TODO ----> ImageUploader for editing css properties
    // TODO ----> BreakPoints for media queries
    // TODO ----> SetProperty for editing css properties
    // TODO ----> FormValidation
    addEventListener("loadeddata", function (e) {
        _console("LOADED DATA", "OYEAH")
    })
    // logo
    // search
    // two menu


    class Use {
        name = "john";
    }

    const assignationTree = {
        type: "Code",
        body: {
            params: [
                {
                    type: "DeclarationExpression",
                    params: [{
                        type: "ReservedKeyword",
                        name: "VariableType",
                        params: [{
                            name: "StringLiteral",
                            value: "const"
                        }]
                    },
                        {
                            type: "VariableKeyword",
                            name: "VariableName",
                            params: [{
                                type: "StringLiteral",
                                value: "age"
                            }]
                        },
                        {
                            type: "Assignation",
                            name: "AssignationExpression",
                            params: [{
                                type: "StringLiteral",
                                value: "="
                            }]
                        },
                        {
                            type: "AssignationValue",
                            name: "ValueExpression",
                            params: [{
                                type: "StringLiteral",
                                value: "john"
                            }]
                        },
                        {
                            type: "DeclarationEnd",
                            name: "EndExpression",
                            params: [{
                                type: "StringLiteral",
                                value: ";"
                            }]
                        }
                    ],
                },
            ]
        }
    }
    const classSyntaxTree = {
        type: 'Program',
        body: {
            params: [
                {
                    type: "DeclarationExpression",
                    // name: "ReservedKeyword",
                    params: [
                        {
                            type: "StringLiteral",
                            value: "class"
                        },
                        {
                            type: "StringLiteral",
                            name: "ClassName",
                            params: [{
                                type: "StringLiteral",
                                value: "Use"
                            }]
                        },
                        {
                            type: "BodyTag",
                            name: "OpenBracket",
                            params: [{
                                type: "StringLiteral",
                                value: "{"
                            }]
                        },
                        {
                            type: "Code",
                            body: {
                                params: [
                                    {
                                        type: "DeclarationExpression",
                                        params: [
                                            {
                                                type: "VariableKeyword",
                                                name: "VariableName",
                                                params: [{
                                                    type: "StringLiteral",
                                                    value: "age"
                                                }]
                                            },
                                            {
                                                type: "Assignation",
                                                name: "AssignationExpression",
                                                params: [{
                                                    type: "StringLiteral",
                                                    value: ":"
                                                }]
                                            },
                                            {
                                                type: "AssignationValue",
                                                name: "ValueExpression",
                                                params: [{
                                                    type: "NumberLiteral",
                                                    value: 25
                                                }]
                                            },
                                            {
                                                type: "DeclarationEnd",
                                                name: "EndExpression",
                                                params: [{
                                                    type: "StringLiteral",
                                                    value: ";"
                                                }]
                                            }
                                        ],
                                    },
                                ]
                            }
                        },
                        {
                            type: "BodyTag",
                            name: "CloseBracket",
                            params: [{
                                type: "StringLiteral",
                                value: "}"
                            }]
                        }
                    ]
                },
            ]
        }
    }

    // const visitorManager = new VisitorManager(new DeclarationExpression(classSyntaxTree.body.params[0].type, classSyntaxTree.body.params));
    // const tokens = tokenizer(`class Use {
    //     age: 25;
    //     name: 'momo';
    //     email: 'momo@262mom_5252o.com';
    // }`);

    // parser(tokens);
    const elementBox = new ListElementBox();
    let isOn = false;


    const htmlConfigAst = {
        name : "Program",
        body: {
            params: [
                {
                    name: "OpenTag",
                    params: [{
                        type: "SmallerLiteral",
                        value: "<"
                    }]
                },
                {
                    name: "CloseTag",
                    params: [{
                        type: "GreaterLiteral",
                        value: ">"
                    }]
                },
                {
                    name: "CloseTagEn",
                    params: [{
                        type: "CloseLiteral",
                        value: "/"
                    }]
                },
                {
                    name: "End",
                    params: [{
                        type: "EndLiteral",
                        value: ";"
                    }]
                },
                {
                    name: "Separation",
                    params: [{
                        type: "SeparationLiteral",
                        value: ","
                    }]
                },
                {
                    name: "OpenBracket",
                    params: [{
                        type: "ObjectLiteral",
                        value: "{"
                    }]
                },
                {
                    name: "OpenParen",
                    params: [{
                        type: "PrecedenceLiteral",
                        value: "("
                    }]
                },
                {
                    name: "OpenParen",
                    params: [{
                        type: "PrecedenceLiteral",
                        value: ")"
                    }]
                },
                {
                    name: "CloseBracket",
                    params: [{
                        type: "ObjectLiteral",
                        value: "}"
                    }]
                },
                {
                    name: "Name",
                    params: [{
                        type: "StringLiteral",
                        value: ""
                    }]
                },
                {
                    name: "AssignationOperator",
                    params: [{
                        type: "AssignationLiteral",
                        value: "="
                    }]
                },
                {
                    name: "AttributeValue",
                    params: [{
                        type: "StringLiteral",
                        value: ""
                    }]
                },
            ]
        },
    }



    function setElements(strValue) {
        const SPLITTER = "><";
        const singleDivEmpty = RegExp(/^[<][/][a-z]*[>]*/g);
        let listElements = [];
        let test = "";
        let emptyClosingDiv = "";

        strValue.split(SPLITTER).forEach(childString => {
            const missingOpenTag = childString.indexOf("<") !== 0;
            const missingCloseTag = childString.indexOf(">") < childString.length -1;
            if (missingCloseTag) childString += ">";
            if (missingOpenTag) childString = `<${childString}` ;
            emptyClosingDiv = childString.match(singleDivEmpty);
            if (!emptyClosingDiv) listElements.push(childString);
            test += childString;
        })
        // test += emptyClosingDiv;
        // console.log('listElements ::: ', listElements);
        // console.log("test::::   ", test);
        return listElements;
    }
    function getElementTag(valueStr, tagNameReg) {
        const singleDiv = RegExp(/^[<]+[?]*[a-z0-9]+[>]*/g);
        let tags = valueStr.match(singleDiv);
        let tag = "";
        if (tags && tags.length > 0) tag += tags[0].replace(/[<>]/g, "");
        return tag;
    }
    function getAttValues(string, tag, attrValuesRegexRule, attributesReg) {
        let attrValues = string.match(attrValuesRegexRule) || [];
        let innerOpenTag = "";
        // push tagName in the stack to be removed from the innerOpenTagValue
        let attrWithAssignation = string.match(attributesReg);
        attrValues.push(tag);
        // set attributes with assigne operator "="
        if (attrWithAssignation && attrWithAssignation.length > 0) innerOpenTag += attrWithAssignation[0];
        innerOpenTag = innerOpenTag.toString().replace(tag, "");
        attrValues.forEach(a => {
            innerOpenTag = innerOpenTag.replace(a, "");
        });
        // remove the tagName from the attributeValue list
        attrValues.splice(attrValues.indexOf(tag), 1);

        return {attrValues, innerOpenTag};
    }
    // function getOpenTag(tag, attrValues) {
    //     let innerOpenTag = "";
    //     if (attrWithAssignation && attrWithAssignation.length > 0) innerOpenTag += attrWithAssignation[0];
    //     innerOpenTag = innerOpenTag.toString().replace(tag, "");
    //     attrValues.forEach(a => {
    //         innerOpenTag = innerOpenTag.replace(a, "");
    //     });
    //     return attrValues;
    // }
    function pushToAttributes(key, part, dataList) {
        dataList.forEach(e => {
            if (e) part.attributes[key].push(e);
        });
        return part;
    }

    // function pushAssigned(part, attrValue) {
    //     attrValue.forEach(a => {
    //         part.attributes.assigned.push(a);
    //     });
    //     return part;
    // }

    // function tokenizer(input) {
    //     let curent = 0;
    //     let _tokens = []
    //
    //     while (curent < input.length) {
    //         let char = input[curent];
    //
    //         if (char === "<") {
    //             _tokens.push({
    //                 type: "SmallerLiteral",
    //                 value: "<"
    //             });
    //         }
    //         const string = /^[a-zA-Z!@.?0-9_]+/i;
    //
    //         if (string.test(char)) {
    //             _tokens.push({
    //                 type: "StringLiteral",
    //                 value: char
    //             });
    //         }
    //
    //         if (char === ">") {
    //             _tokens.push({
    //                 type: "GreaterLiteral",
    //                 value: ">"
    //             });
    //         }
    //
    //         if (char === "/") {
    //             _tokens.push({
    //                 type: "CloseLiteral",
    //                 value: "/"
    //             });
    //         }
    //
    //         curent++
    //     }
    //     return _tokens;
    // }

 //    let html = `<div>
 //      <div hidden address=2 patate='rouge' age='vert' paneau>MARTEAU</div>
 //      <div dark avion=2 place='bleu' name='like' rose>BATEAU</div>
 //      <div real steps=2 color='jaune' choice='absent' noir></div>
 // </div>`;


    let html = `<div><pre naked tom='{"AbZE":"abc uiuuhiu oioihoho oii oojpojpojpjpoannnsbnn", "efg":"abc annnsbnn"}' style="align-content: baseline; color: #00aac7; background: black; ">Momo</pre></div>`;

    const tagNameReg = RegExp(/^[<].[?a-z0-9]+.[>]*/g);
    const attributesReg = RegExp(/([a-z-_0-9][=]*)[^><]+/g);
    const attributeValueReg = RegExp(/[a-z#!0-9-_]*([:=]*["'{]*[a-zA-Z0-9-#{\s]*[:=}'"]+[\s]*.[a-zA-Z0-9.!&@#*,;-_"'\s}]*[\s]*([,;]|[\s])+[\s]*)+/g);
    const hasCloseTagReg = RegExp(/[<][/]/g);
    const singleElementReg = RegExp(/[/][>]/g);
    const whiteSpaceReg = RegExp(/\s/g);

    let listElements = setElements(html);

    listElements.forEach(string => {

        // console.log("element :::::    ", string);


        let part = {
            openTag: "<",
            tagName: "",
            attributes: {assigned: [], unassigned: []},
            closeTag : ">",
        };

        // like img or input
        const singleElements = string.match(singleElementReg);
        const parentElement = string.match(hasCloseTagReg);
        let tag = getElementTag(string, tagNameReg);
        const attData = getAttValues(string, tag, attributeValueReg, attributesReg);
        // get attributes values
        let attrValues = attData.attrValues;
        let innerOpenTag = attData.innerOpenTag;
        // push to the part object attribute assigned list
        part = pushToAttributes("assigned", part, attrValues);
        // push to the part object attribute assigned list
        // cast innerOpenTag to array
        // push to the part object attribute unassigned list
        part = pushToAttributes("unassigned", part, innerOpenTag.replace(whiteSpaceReg, ",").split(","));
        // set the part object tagName property
        part.tagName = tag;
        part.isSingleElement = !!(!parentElement || parentElement.length <= 0 && singleElements && singleElements.length > 0);
        // console.log("part",  part);
        if (part.attributes && part.attributes.assigned.length > 0) {
            part.attributes.assigned.forEach(asa => {
                if (asa) asa.toString();
                const keyReg = RegExp(/.*[a-zA-Z_0-9-]+[=]+/g);
                const innerKeyReg = RegExp(/[a-zA-Z_0-9-]*([\s]*)+[:]+([\s]*)+/g);
                const keys = asa.match(keyReg);

                if (keys && keys.length) {
                    keys.forEach(k => {
                        const key = k.replace("=", "").toString();
                        // console.log(asa.toString().replace(key, "").replace(/[=]*/g, ""));
                        const val = asa.toString().replace(key, "").replace(/[=]*/g, "");
                        const isStringObject = (val && val.trim().indexOf("{") === 0
                            || val && val.trim().indexOf("{") === 1) && (val.trim().indexOf("}") === val.trim().length - 2
                            || val.trim().indexOf("}") === val.trim().length - 1);
                        if (isStringObject) {
                            // console.log(`{${[key]}: ${val.replace("'{", "{").replace("'}", '}')}`);
                        }

                        if (!isStringObject) {
                            const values = val.split(";");
                            values.forEach(v => {
                                const dataToObj = makeUcFirstRemoveDash(v, innerKeyReg);
                                // console.log("dataToObj:::: ----------------------------- ", dataToObj);
                            })
                        } else {
                            let keyToUseHere = val.replace(/[{}"]*/g, "");
                            const dataToObj = makeUcFirstRemoveDash(keyToUseHere, innerKeyReg);
                            // console.log("dataToObj::::else ----------------------------- ", dataToObj);
                        }
                    })
                }

            })
        }
    })

    function makeUcFirstRemoveDash(value, innerKeyReg) {
        // console.log("value:::: ", value);
        const dataToObj = value.match(innerKeyReg);
        let res = {};
        if (dataToObj) {
            const dataToObjKeys = dataToObj.filter(d => d !== ":");
            dataToObjKeys.forEach(dOK => {
                if (dOK) {
                    const shouldBeKamel = dOK.indexOf("-") !== -1;
                    if (shouldBeKamel) {
                        let firstPart = dOK.split("-")[0];
                        let secPart = dOK.split("-")[1];
                        dOK = firstPart + secPart.substr(0, 1).toLocaleUpperCase() + secPart.substr(1);
                    } else if (dOK) {
                        // console.log("value:::: value:::: dOK dOK ", value.substr(value.indexOf(dOK) - 1));
                        let current = 0;
                        let char = value[current];
                        let key = "";
                        while (char !== ":") {
                            char = value[current];
                            if (innerKeyReg.test(key) || /\s/g.test(char)) {
                                char = value[current];
                                current++;
                                continue;
                            }
                            key+=char;
                            current++;
                        }
                        if (value.substr(value.indexOf(dOK) - 1).split(':') && value.substr(value.indexOf(dOK) - 1).split(':').length > 0) {
                            // console.log("key ::::::::  ", value.substr(value.indexOf(dOK) - 1).split(':'));
                        }
                    }

                    let data = {
                        ...res,
                        ...{[dOK.replace(":", "")]: value.split(":")[1]}
                    }

                    Object.assign(res, data);
                }
            })
        }
        // console.log("res:::::::::::::::::::  ::::  ::: :::: ::: :::: ", res);
        return res;
    }

    const skills = [
        {
            header: "JAVASCRIPT",
            content: "",
            footer: "",
            url: "javascript",
            nextPage: JsPage,
            starContent: "32.52 KB",
            stars: 3,
        },
        {
            header: "Node JS",
            content: "",
            url: "node_js",
            nextPage: NodePage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "React JS",
            content: "",
            url: "react_js",
            nextPage: ReactJsPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "React Native",
            url: "react_native",
            nextPage: ReactNativePage,
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Flutter",
            url: "flutter",
            nextPage: FlutterPage,
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Java",
            content: "",
            url: "java",
            nextPage: JavaPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "PHP",
            url: "php",
            nextPage: PhpPage,
            content: "",
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "SQL",
            content: "",
            url: "sql",
            nextPage: SqlPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "GRAPH-QL",
            content: "",
            footer: "",
            url: "graph_ql",
            nextPage: GraphQlPage,
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "HTML",
            content: "",
            url: "html",
            nextPage: HtmlPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "CSS",
            content: "",
            url: "css",
            nextPage: CssPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "Librairies CSS",
            content: "",
            url: "css_libs",
            nextPage: CssLibsPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
        {
            header: "UML",
            content: "",
            url: "uml",
            nextPage: UmlPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 4,
        },
    ];

    const modules = [
        {
            header: "JSPATTERN",
            content: "",
            url: "npm_jspattern",
            nextPage: CssLibsPage,
            footer: "",
            starContent: "32.52 KB",
            stars: 3,
        },
    ];

    // function getRouteParams({nextPage}) {
    //     return {
    //         stateObserver, useState, navigation, nextPage: nextPage
    //     }
    // }
    //
    // /**
    //  * @param url {string}
    //  * @param nextPage {Function}
    //  * @param title {string}
    //  * */
    // function goToUrl({url, nextPage, title = ""}) {
    //     if (!url || !nextPage) return;
    //     console.log("ok");
    //     return navigation.goToUrl({url: url && `/${url}`, title: title || document.title, ...getRouteParams({nextPage: nextPage})})
    // }

    const cardStyle = ({borderRadius, shadow = {}, backgroundColors= [] = []}) => {
        const {shadowColors= [], elevation, shadowBottom, shadowRight, shadowPercents, shape, shapePercent} = shadow;
        const backgroundColorOne = backgroundColors[0] || "#303082";
        const backgroundColorTwo = backgroundColors[1] || "rgba(58, 0, 121, 0.78)";
        const shadowPercentOne = shadowPercents[0] || "30%";
        const shadowPercentTwo = shadowPercents[1] || "30%";
        const shadowColorOne = shadowColors[0] || `rgba(1 1 10 / ${shadowPercentOne})`;
        const shadowColorTwo = shadowColors[1] || `rgba(1 1 10 / ${shadowPercentTwo})`;
        const _ellipsePercent = shapePercent || "10%";
        const _shape = shape || "ellipse";
        const radius = borderRadius || "12px";
        const _elevation = elevation || "1px";
        const right = shadowRight || "1px";
        const bottom = shadowBottom || "1px";

        return {
            background: `radial-gradient(${_shape} at ${_ellipsePercent}, ${backgroundColorOne}, ${backgroundColorTwo})`,
            borderRadius: radius,
            boxShadow: `${right} ${bottom} ${elevation} ${shadowColorOne}, ${right} 2px ${_elevation} ${shadowColorTwo}`
        }
    };

    function customCardStyle() {
        return cardStyle({shadow: {
                shape: "circle", shapePercent: "33%",
                shadowColors: ["rgba(33,33,58,0.8)", "rgba(128,128,128,0.65)"], elevation: "5px", shadowBottom: "1px", shadowRight: "1px", shadowPercents: "30%",
            }, borderRadius: "12px", backgroundColors: ["rgba(33,33,58,0.8)", "rgba(44,44,79,0.8)"]})
    }

    function disableReturn() {
        history.forward();
    }


    window.addEventListener("popstate", function (e) {
        let option = {url: e.target.location.pathname, title: pageTitle, stateObserver, useState, navigation, nextPage: null, dataSource};
        if (dataSource) dataSource.cancel();
        else throw new Error("no dataSource");
        const last = dataSource.getValue();
        option.nextPage = last && last.nextPage || Home;
        navigation.goToUrl({...option, isLast: true});
    });

    return Section({
        children: [
           // Section({
           //      id: "hide-me",
           //      className: "custom-table-form__icon",
           //      style: {marginTop: "10px"},
           //      children: [
           //          Icon({
           //              className: "custom-table-form__icon",
           //              textContent: "remove_circle_outline",
           //              onclick: function (e) {
           //                  _console("remove_circle_outline click", e);
           //              }
           //          }),
           //          Icon({
           //              className: "custom-table-form__icon",
           //              textContent: "add_circle_outline",
           //              id: "abcd",
           //              onclick: function (e) {
           //                  _console("add_circle_outline click", e);
           //                  const prods = store.products || [];
           //                  // demo
           //                  [{id: 1, name: "iphone",},
           //                      {id: 2, name: "samsung",},
           //                      {id: 3, name: "huawei",},
           //                  ].forEach(p => prods.push(p));
           //
           //                  // dispatch({
           //                  //     type: actionsTypes.PRODUCTS,
           //                  //     products: prods
           //                  // });
           //
           //                  setText('max');
           //                  StateManager._dataSource.setValue(prods);
           //                  console.log(StateManager._dataSource.getValue());
           //                  updateDom({element: e.target, data: {style: {border: "1px solid red", background: "red"}}});
           //                  elementBox.simulate("ooomo");
           //                  _console("add_circle_outline store", store);
           //              }
           //          })
           //      ]
           //  }),
            // Header
            NavBar(params),
            // HERO
            Section({
                // block--dark block--skewed-left hero
                className: ["hero", "block", "block--skewed-left", "block--dark"],
                children: [
                    Grid({
                        className: "hero__grid",
                        style: {minHeight: "400px"},
                        gtc: "repeat(auto-fit, minmax(290px, 1fr))",
                        ai: "center",
                        ji: "center",
                        children: [
                            Section({
                                children: [
                                    Header({
                                        className: "hero__header",
                                        textContent: "Deux balises HTML suffisent. JSPattern n'utilise aucune dépendance!"}),
                                    Paragraph({
                                        className: "hero__text",
                                        textContent: `JSPattern utilise uniquement la puissance des classes et des design patterns.
                                    Il propose une approche plus maniable, facile à déboguer et est prêt pour de futures évolutions.`}),
                                    Section({
                                        className: "hero-buttons",
                                        children: [
                                            Button({
                                                onclick: function (e) {
                                                    navigation.goToUrl({url: "/contact", title: "Contact", stateObserver, useState, navigation, nextPage: Contact});

                                                    // stateObserver, useState, navigation, nextPage: nextPage
                                                    // navigation.goToUrl({url: "/contact", title: "Contact", ...getRouteParams(Contact), nextPage: Contact})
                                                },
                                                textContent: "Get started", className: ["button", "fw-600", "hover-animation-button"]
                                            }),
                                            Button({
                                                textContent: "GitLab", className: ["button", "fw-600", "hover-animation-button--primary"],
                                                onclick: (e) => {
                                                    console.log(e);
                                                }
                                            }),
                                        ]
                                    })
                                ]
                            }),
                            // Terminal(htmlStartCode),
                            Section({
                                className: ["terminal-custom"],
                                children: [
                                    Card({
                                        className: ["terminal-custom__card"],
                                        children: [
                                            Article({
                                                children: [
                                                    Section({
                                                        className: "terminal__icons",
                                                        children: [
                                                            Icon({textContent: "circle", className: "terminal__icon"}),
                                                            Icon({textContent: "circle", className: "terminal__icon"}),
                                                            Icon({textContent: "circle", className: "terminal__icon"}),
                                                        ]
                                                    }),
                                                    Span({
                                                        className: "termina__line"
                                                    }),
                                                    Article({
                                                        className: "terminal__icons",
                                                        style: {minHeight: "20rem",minWidth: "20rem"},
                                                        children: [
                                                            Paragraph({textContent: htmlCode})
                                                        ]
                                                    }),
                                                ]
                                            })
                                        ]
                                    })
                                ]
                            })
                        ]
                    })
                ]
            }),
            // Showcase
            Section({
                className: ["container"],
                children: [
                    Section({
                        className: "showcase",
                        children: [
                            Section({
                                className: ["showcase__heading"],
                                children: [
                                    Header({
                                        className: "showcase__header",
                                        textContent: "Une application composée essentiellement de classes"
                                    }),
                                    Paragraph({
                                        className: "showcase__text",
                                        textContent: `Créée avec un ensemble de modèles (Pattern) comme le Patron de Gabarit (Template Method Pattern), le patron Chaîne de Responsabilité (Chain Of Responsibility Pattern), le Patron de Médiation (Mediator Pattern), etc... 
                    `}),
                                ]
                            }),
                            CardList({header:  "Expériences Codage", items: skills, navigation: navigation.goToUrl}),
                            CardList({header:  "MODULE - NPM", items: modules, navigation: ({title}) => navigation.goToUrl({url: "javascript", title: title, nextPage: JsPage})}),
                        ]
                    })
                ]
            })
        ]
    });
};

// const OldChild = Section({
//     style: {margin: "1rem 2rem"},
//     children: [
//         Section({children: [
//                 Header({textContent: "Custom Form"}),
//                 Form({className: "custom-table-form", items: formItems.fields}),
//             ]
//         }),
//         Section({
//             className: "custom-table-form__icon",
//             children: [
//                 Icon({
//                     className: "custom-table-form__icon",
//                     textContent: "remove_circle_outline",
//                     onclick: function (e) {
//                         _console("remove_circle_outline click", e);
//                     }
//                 }),
//                 Icon({
//                     className: "custom-table-form__icon",
//                     textContent: "add_circle_outline",
//                     id: "abcd",
//                     onclick: function (e) {
//                         _console("add_circle_outline click", e);
//                         const prods = store.products || [];
//                         // demo
//                         [{id: 1, name: "iphone",},
//                             {id: 2, name: "samsung",},
//                             {id: 3, name: "huawei",},
//                         ].forEach(p => prods.push(p));
//
//                         dispatch({
//                             type: actionsTypes.PRODUCTS,
//                             products: prods
//                         });
//
//                         updateDom({element: e.target, data: {style: {backgroundColor: "red"}}});
//
//                         _console("add_circle_outline store", store);
//                     }
//                 })
//             ]
//         }),
//         Section({
//             tagName: "header",
//             children: [
//                 Header({textContent: "Custom Table"})
//             ]
//         }),
//         Section({
//             children: [
//                 Header({tagName: "h1", textContent: "Heading"}),
//                 Header({tagName: "h2", textContent: "Heading"}),
//                 Header({tagName: "h3", textContent: "Heading"}),
//                 Header({tagName: "h4", textContent: "Heading"}),
//                 Header({tagName: "h5", textContent: "Heading"}),
//                 Header({tagName: "h6", textContent: "Heading"}),
//             ]
//         }),
//         Table({
//             children: [
//                 TableHeader({
//                     children: [
//                         TableRow({
//                             children: [
//                                 DataCell({textContent: "ID"}),
//                                 DataCell({textContent: "Email"}),
//                                 DataCell({textContent: "Full name"}),
//                             ]
//                         }),
//                     ]
//                 }),
//                 TableBody({
//                     children: [
//                         TableRow({
//                             children: [
//                                 DataCell({textContent: "1"}),
//                                 DataCell({textContent: "buzz@foo.com"}),
//                                 DataCell({textContent: "Fuz Buzz"}),
//                             ]
//                         }),
//                     ]
//                 }),
//                 TableFooter({
//                     children: [
//                         TableRow({
//                             children: [
//                                 DataCell({textContent: "Nice table"}),
//                             ]
//                         }),
//                     ]
//                 }),
//             ]
//         }),
//         tableCreator.showCartContent(),
//         Figure({className: "home-image-easy", style: { position: "relative" }, children: [
//                 Image({src : img, style: {width: "10%", objectFit: "cover"}}),
//             ]}),
//         Article({className: "home-image-easy", style: { position: "relative" }, children: [
//                 Article({textContent: "HELLO"}),
//             ]}),
//         Grid({
//             gtc: "1fr 1fr",
//             children: [
//                 Section({
//                     children: [
//                         Paragraph({textContent: "Shamamal"})
//                     ]
//                 }),
//                 Section({
//                     children: [
//                         Paragraph({textContent: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis, voluptates"}),
//                         Paragraph({textContent: "Lorem ipsum dolor sit"})
//                     ]
//                 }),
//             ]
//         }),
//         Section({
//             children: [
//                 List({
//                     children: [
//                         ListItem({textContent: "Hey"}),
//                         ListItem({textContent: "Ho"}),
//                         ListItem({textContent: "Hu"}),
//                         ListItem({textContent: "Ha"}),
//                     ]
//                 }),
//                 Button({
//                     textContent: "Documentation",
//                     onclick: function (e) {
//                         console.log(e);
//                         navigation.goToUrl({url: "/docs", title: "Contact", stateObserver, useState, navigation, nextPage: Docs});
//                     }
//                 }),
//                 Button({
//                     textContent: "Contact Us",
//                     onclick: function (e) {
//                         console.log(e);
//                         navigation.goToUrl({url: "/contact", title: "Contact", stateObserver, useState, navigation, nextPage: Contact});
//                     }
//                 }),
//                 DefinitionList({
//                     children : [
//                         DefinitionListTerme({textContent: "Denim (semigloss finish"}),
//                         DefinitionListDescription({textContent: "Ceiling"}),
//                         DefinitionListTerme({textContent: "Denim (eggshell finish)"}),
//                         DefinitionListTerme({textContent: "Evening Sky (eggshell finish)"}),
//                         DefinitionListDescription({textContent: "Layered on the walls"})
//                     ]
//                 })
//             ]
//         }),
//         Section({
//             className: "table-generator__table",
//             children: [
//                 tableCreator.showCartContent()
//             ],
//         }),
//         Section({
//             className: "table-generator__payloads",
//             children: [
//                 Section({
//                     className: "table-generator__payloads-item",
//                     children: [
//                         Header({
//                             tagName: "h2",
//                             className: "table-generator__title",
//                             textContent: "JS Code",
//                         }),
//                         Article({
//                             innerHTML: jsCode
//                         })
//                     ]
//                 }),
//                 Section({
//                     className: "table-generator__payloads-item",
//                     children: [
//                         Header({
//                             tagName: "h2",
//                             className: "table-generator__title",
//                             textContent: "HTML Code",
//                         }),
//                         Article({
//                             innerHTML: htmlCode
//                         })
//                     ]
//                 }),
//                 Section({
//                     className: "table-generator__payloads-item",
//                     children: [
//                         Header({
//                             tagName: "h2",
//                             className: "table-generator__title",
//                             textContent: "CSS Code"
//                         }),
//                         Article({
//                             innerHTML: cssCode
//                         })
//                     ]
//                 }),
//             ]
//         })
//     ]
// });

import {Parameters} from "./";
import {isArray} from "../../utils/helpers";

export class FormClass extends Parameters {
    constructor(props) {
        super(props)
    }

    /** @override **/
    setObjectList(parent, children, className, tagName) {
        children = isArray(children) && children || [];
        children.forEach(child => {
            const element = this.domManager.createElement("input").getElement();
            element.setAttribute("placeholder", child.placeholder);
            element.className = "input";
            element.onkeydown = element.onkeydown ||function (event) {this._inputKeyDown(child.name, event)};
            parent.appendChild(element);
        });
        return this;
    }
    /** @override **/
    getTagName() {
        return "form";
    }
    /** @override **/
    getClassName() {
        return "";
    }
    /** @override **/
    getStyle() {
    }

    /** @override **/
    getName(){
        return "FormClass"
    }
}

/**@interface*/
export class ContainerInterface {
    /**@abstract*/
    saveCurrent(value) {}
    /**@abstract*/
    getTagName() {}
    /**@abstract*/
    getClassName() {}
    /**@abstract*/
    getStyle() {}
    /**@abstract*/
    getName(){}
}

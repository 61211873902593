'use strict'
import {History} from "../../../src/core/memento/History";

export {
    Navigator,
    StateManager,
    ObjectFieldSetter,
    PageSetter,
    PageUpdateSetter,
    ContainerInterface,
    ContainerHandler,
    ParamsHandler,
    Parameters,
    DomManager,
    TableClass,
    TableBodyClass,
    TableHeadClass,
    TableHeaderClass,
    TableFooterClass,
    TableRowClass,
    DataCellClass,
    MainClass,
    ArticleClass,
    FigureClass,
    FormClass,
    HeaderClass,
    IconClass,
    IconButtonClass,
    ImageClass,
    InputClass,
    ListClass,
    ListItemClass,
    MarkClass,
    SpanClass,
    ParagraphClass,
    SectionClass,
    ButtonClass,
    DefinitionListClass,
    DefinitionListTermeClass,
    DefinitionListDescriptionClass,
    GridClass,
    CanvasClass
} from "./src/core/classes";

export {
    StateObserver
} from './src/core/observers';

export {
    Handler,
    ArgumentsHandlerInterface,
    ArgumentsHandler,
    ArgumentsProvider,
    useArgs,
    Arg,
    ArgList,
    AuthenticationHandler,
    DomKeyHandler,
    DomUpdateHandler,
    DomParamsHandler,
    DomFieldHandler,
    NavigationFieldHandler,
    ObserverFieldHandler,
    PageParamsHandler,
    ProviderFieldHandler,
    StateFieldHandler,
    ValidationHandler
} from "./src/core/patterns/chain";

export {
    ObserverInterface,
    Observer,
    Subject,
    DataSource
} from './src/core/patterns/observer';

export {
    Context,
    Provider,
    StoreProvider,
} from './src/core/patterns/state';
export {useReduxProvider} from "./src/core/redux/ReduxProvider";
export {
    isArray,
    isFunction,
    isObject,
    isHome,
    isHtmlElement,
    isHtmlInputElement,
    pageName,
    _console,
    pageTitle,
    setElementAttributes,
    ucFirst,
    createId
} from "./src/utils/helpers";
export {
    Figure,
    Form,
    Canvas,
    Header,
    Table,
    TableHeader,
    TableBody,
    TableFooter,
    TableHead,
    TableRow,
    DataCell,
    Icon,
    Image,
    Input,
    Paragraph,
    Section,
    Article,
    List,
    ListItem,
    Mark,
    Span,
    Button,
    IconButton,
    DefinitionList,
    DefinitionListTerme,
    DefinitionListDescription,
    Grid
} from "./src/components";
import {
    StateObserver,
    Navigator,
    PageSetter,
    PageUpdateSetter,
    DomParamsHandler,
    DomUpdateHandler,
    PageParamsHandler,
    isHome
} from "./";

import {DomKeyHandler} from "./";
import {DataSource} from "../../../src/core/memento/DataSource";


export class App {
    constructor(routes, initialState, reducer) {
        routes = routes || {};
        const stateObserver = new StateObserver();
        const history = new History();
        const dataSource = new DataSource(history);
        const navigation = new Navigator({
            url: self.location.pathname,
            title: isHome ? "Home" : document.title,
            dataSource
        });

        // dataSource.cancel();
        const child = navigation.start(routes);
        // START
        const domParamsHandler = new DomParamsHandler(null);
        const domKeyHandler = new DomKeyHandler(domParamsHandler);
        const paramsHandler = new PageParamsHandler(domKeyHandler);
        const pageSetter = new PageSetter(paramsHandler, {stateObserver, navigation, dataSource, initialState: initialState, reducer: reducer});
        // UPDATE

        const domUpdateHandler = new DomUpdateHandler(null);
        const pageUpdateSetter = new PageUpdateSetter(domUpdateHandler);
        pageSetter.handle({...child, updateDom: function (value) {
                pageUpdateSetter.handle(value);
            }});
    }
}

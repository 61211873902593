/**@interface*/
export class ObserverInterface {
    // PUSH STYLE -> add parameter value // when each observer needs the same type of value
    // PULL STYLE -> observer can choose the data they need ! Best solution ! Beware of new coupling between the concrete observer and the concrete subject (subject -> observer)
    /**@abstract*/
    update(){}

    // DUE TO PULL STYLE
    /**@abstract*/
    getDataSource(){}
    /**@abstract*/
    getValue(){}

    // DU TO THE APP
    /**@abstract*/
    setInitialData(initialData){}
    /**@abstract*/
    getInitialData(){}
    /**@abstract*/
    setDataSource(dataSource){}
}

/**
 * @param iterator {IteratorInterface }
 * @param callback {Function }
 *
 * */
export const useIterator = function ({iterator, callback}) {
    while(iterator.hasNext()) {
        const current = iterator.current();
        callback(current)
        iterator.next();
    }
}


import {ContainerHandler} from "./";

export class ImageClass extends ContainerHandler {
    constructor(props) {
        super(props);
    }
    /** @override **/
    getTagName() {
        return "img";
    }
    /** @override **/
    getClassName() {
        return "image";
    }
    /** @override **/
    getStyle() {
    }

   
    /** @override **/
    getName(){
        return "ImageClass"
    }
}
